import { dataCategory } from "../../../../../components/selectCategory";
import {
    Management,
    b64Uri,
    getElement,
    getJson,
    resetForm,
    resetSelect2Ajax,
    setSelect2Ajax,
} from "../../../../../helpers";

export class CategoryModal extends HTMLDivElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });

        Form.elements.category_id.addEventListener("aj:select2", (e) => {
            let data = e.detail.data;

            if (data.accountingplan) {
                setSelect2Ajax(
                    Form.elements.accountingplan_id,
                    data.accountingplan.id,
                    `${data.accountingplan.number} - ${data.accountingplan.name}`
                );
            } else {
                resetSelect2Ajax(Form.elements.accountingplan_id);
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        dataCategory.categories = [];
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));

        // Filtrar para no mostrar descendientes
        let arrayFlatten = CategoryModal.flattenDeep(data.descendants);
        dataCategory.categories = arrayFlatten.map((obj) => obj.id);

        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.name;
        Form.elements.code.value = data.managements.find(
            (item) => item.company_id == Management.company_id
        ).pivot.code;

        dataCategory.categories.push(data.id);

        if (data.category) {
            setSelect2Ajax(
                Form.elements.category_id,
                data.category.id,
                data.category.name
            );
        }

        if (data.accountingplan) {
            setSelect2Ajax(
                Form.elements.accountingplan_id,
                data.accountingplan.id,
                `${data.accountingplan.number} - ${data.accountingplan.name}`
            );
        }
    }

    static flattenDeep(arr) {
        return arr.reduce((acc, obj) => {
            acc.push(obj);
            if (obj.descendants && obj.descendants.length > 0) {
                acc = acc.concat(CategoryModal.flattenDeep(obj.descendants));
            }
            return acc;
        }, []);
    }
}

window.customElements.define("category-modal", CategoryModal, {
    extends: "div",
});
