import {
    alertHTML,
    b64Uri,
    bodyRequest,
    getData,
    getElement,
    requestOptions,
    resetSelect2Ajax,
    tryAsyncFetch,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";

export const dataProductCodes = { codes: [], dt: null };

export class ProductCodesModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        this.addEvents();
        this.dt();
    }

    addEvents() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnAdd = Target.closest("[data-target=add]");

            if (Target.name == "save") {
                if (dataProductCodes.codes.length) {
                    const ModalParent = getElement(this.id.split("_")[1]);
                    const FormParent = getElement(
                        "[data-form=form]",
                        ModalParent
                    );

                    FormParent.elements.codes.value = b64Uri(
                        dataProductCodes.codes
                    );
                    Form.elements.closeModal.click();
                }
            }

            if (getData(Target, "data-target") == "delete") {
                // Eliminar el target
                dataProductCodes.codes = dataProductCodes.codes.filter(
                    (item) => item.code != getData(Target, "data-code")
                );

                // recargar la data
                dataProductCodes.dt.reloadData(dataProductCodes.codes);
            }

            if (BtnAdd && Form.elements.code.value.trim().length) {
                let code = {
                    code: Form.elements.code.value.trim(),
                    buttons: null,
                };

                if (
                    !dataProductCodes.codes.find(
                        (item) => item.code == code.code
                    )
                ) {
                    dataProductCodes.codes.push(code);
                }

                dataProductCodes.dt.reloadData(dataProductCodes.codes);

                Form.elements.code.value = "";
            }
        });
    }

    dt() {
        let configDt = {
            ordering: false,
            info: false,
            lengthChange: false,
            stateSave: false,
            pageLength: 5,
            searching: false,
        };

        configDt.columns = [
            {
                data: "code",
            },
            {
                data: "buttons",
                width: "5%",
                class: "text-center",
                render: (data, type, row) => {
                    let buttons = `<i class="fas fa-trash-alt text-danger" role="button" data-target="delete" data-code="${row.code}"></i>`;
                    return buttons;
                },
            },
        ];

        dataProductCodes.dt = new Dt(configDt);
        dataProductCodes.dt.dataTable(getElement("[data-table=dt]", this));
    }
}

window.customElements.define("productcodes-modal", ProductCodesModal, {
    extends: "div",
});
