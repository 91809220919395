import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import { CSRF, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import { AccountingplanModal } from "./modals/store";

class AccountingPlanModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        //Acciones
        BtnNew?.addEventListener("click", () => {
            AccountingplanModal.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "data-url"),
                getData(BtnNew, "title")
            );
        });

        DtElement?.addEventListener("click", (e) => {
            const btnEdit = e.target.closest("[name=btnEditAccountingPlans]");
            if (btnEdit) {
                AccountingplanModal.edit(
                    getData(btnEdit, "data-bs-target"),
                    getData(btnEdit, "data-url"),
                    getData(btnEdit, "title"),
                    getData(btnEdit, "data-data")
                );
                let data = JSON.parse(atob(btnEdit.getAttribute("data-data")));
                dataAccounting.concat["self"] = data.id;
                dataAccounting.accountingplans = Object.values(
                    dataAccounting.concat
                );
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("accountingplansUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "number" },
            { data: "name" },
            { data: "parent" },
            { data: "journal" },
            { data: "group" },
            {
                data: "status",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtAp = new Dt(configDt);
        DtAp.dataTable(DtElement);
        // End DataTable
    }
}

window.customElements.define("accountingplans-module", AccountingPlanModule, {
    extends: "section",
});
