import {
    CSRF,
    clearCurrency,
    formatCurrency,
    getData,
    getElement,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";

class CollectionProvisionsModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const Form = getElement("[name=formFilter]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        let filter = {
            receiptstart: null,
            receiptend: null,
            accounted: null,
        };

        configDt.ajax = {
            url: getData("collectionProvisionsUrlDt"),
            type: "POST",
            data: (data) => {
                data.filter = filter;
            },
            beforeSend: (request) => {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "id", width: "5%", className: "text-center" },
            {
                data: "created_at",
                width: "6%",
            },
            { data: "paymentMethod" },
            { data: "date" },
            { data: "bank" },
            { data: "amount", className: "text-end" },
            { data: "receipt", width: "6%", className: "text-center" },
            { data: "accounted", width: "6%", className: "text-center" },
        ];

        configDt.footerCallback = (row, data, start, end, display) => {
            let pageTotal = data.reduce(
                (acc, item) => {
                    acc.amount = clearCurrency(acc.amount.toString());
                    item.amount = clearCurrency(item.amount.toString());
                    acc.amount += item.amount;

                    return acc;
                },
                { amount: 0 }
            );

            pageTotal = formatCurrency(pageTotal.amount);
            const DtFoot = getElement("tfoot", DtElement);
            DtFoot.firstElementChild.firstElementChild.nextElementSibling.innerHTML =
                pageTotal;
        };

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        Form.elements.search.addEventListener("click", () => {
            let body = new FormData(Form);
            filter = Array.from(body).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
            T.ajax.reload();
        });

        Form.elements.reset.addEventListener("click", () => {
            filter = {
                receiptstart: null,
                receiptend: null,
                accounted: null,
            };
            T.ajax.reload();
        });
    }
}

window.customElements.define(
    "collectionprovisions-module",
    CollectionProvisionsModule,
    {
        extends: "section",
    }
);
