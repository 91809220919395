import { Dt } from "../../../../plugins/datatable";
import { CSRF, getData, getElement } from "../../../../helpers";
import { RoleModal } from "./modals/store";
class RoleModule extends HTMLElement {
    connectedCallback() {
        this.test();
        this.addEvents();
        this.load();
    }

    test() {
        // Ese método sirve solo para hacer pruebas
        // Borrar o comentar su contenido pero no el método
        // console.log(this);
    }

    load() {
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("rolesUrlDt"),
            type: "POST",
            beforeSend: (request) =>
                request.setRequestHeader("X-CSRF-TOKEN", CSRF),
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name", width: "20%" },
            { data: "description" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            // tippy(".tippy-tooltips");
        };

        const DtCompanies = new Dt(configDt);
        DtCompanies.dataTable(DtElement);
        // End DataTable
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const T = e.target;
            const BtnNew = T.closest("[name=new]");

            if (BtnNew) {
                RoleModal.create(
                    getData(BtnNew, "data-bs-target"),
                    getData(BtnNew, "data-url"),
                    getData(BtnNew, "title")
                );
            }

            const BtnEdit = T.closest("[name=edit]");

            if (BtnEdit) {
                RoleModal.edit(
                    getData(BtnEdit, "data-bs-target"),
                    getData(BtnEdit, "data-url"),
                    getData(BtnEdit, "title"),
                    getData(BtnEdit, "data-data")
                );
            }
        });
    }
}

window.customElements.define("role-module", RoleModule, {
    extends: "section",
});
