import tippy from "tippy.js";
import { b64Uri, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import {
    addresses,
    address,
    setMain,
    remove,
    setBilling,
} from "./modalAddress";

class AddressesModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.info(this);
        // Start DataTable
        let configDt = {
            ordering: false,
            info: false,
            lengthChange: false,
            stateSave: false,
            pageLength: 5,
            dom: "ftp",
        };

        configDt.columns = [
            { data: "id", with: "3%", class: "text-center" },
            {
                data: "name",
                render: (data, type, row) => {
                    let email = "";
                    let tel = "";
                    let contact = "";

                    let address = `${row.address}, `;

                    if (row.location) {
                        address += `${row.location}, `;
                    }

                    address += row.city;

                    if (row.province) {
                        address += ` ${row.province}, `;
                    } else {
                        address += ", ";
                    }

                    address += `${row.zip} ${
                        row.country?.country ?? row.country?.text
                    }`;

                    address = `<i class="fa-solid fa-map-location-dot text-secondary mx-1 tippy-tooltips" data-tippy-content="${address}" data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;

                    let c = row.contacts.find((c) => c.main);

                    if (c) {
                        contact = `<i class="fa-solid fa-user text-info mx-1 tippy-tooltips" data-tippy-content="${c.name}" data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;

                        if (c.email?.email) {
                            email = `<i class="fa-solid fa-at text-info mx-1 tippy-tooltips" data-tippy-content="${c.email.email}" data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;
                        }

                        if (c.tel?.tel) {
                            tel = `<i class="fa-solid fa-phone text-success mx-1 tippy-tooltips" data-tippy-content="${c.tel.tel}"  data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;
                        }
                    }

                    let name = data + address + contact + email + tel;

                    return name;
                },
            },
            {
                data: "main",
                width: "7%",
                class: "text-center",
                render: (data, type, row) => {
                    let checked = "";
                    if (data) {
                        checked = "checked";
                    }
                    let checkBox = `<div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="main" value="${row.id}" ${checked}>
                                    </div>`;
                    return checkBox;
                },
            },
            {
                data: "billing",
                width: "7%",
                class: "text-center",
                render: (data, type, row) => {
                    let checked = "";
                    if (data) {
                        checked = "checked";
                    }
                    let checkBox = `<div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="billing" value="${row.id}" ${checked}>
                                    </div>`;
                    return checkBox;
                },
            },
            {
                data: "buttons",
                width: "15%",
                class: "text-center",
                render: (data, type, row) => {
                    let buttons = `<i class="fas fa-pencil text-warning me-1" role="button" data-target="edit" data-id="${row.id}"></i>
                                   <i class="fas fa-trash-alt text-danger" role="button" data-target="delete" data-id="${row.id}"></i>`;
                    return buttons;
                },
            },
            // { data: "email", class: "d-none" },
            // { data: "tel", class: "d-none" },
        ];

        configDt.drawCallback = function () {
            tippy(".tippy-tooltips");
        };

        const DtAddresses = new Dt(configDt);
        DtAddresses.dataTable(getElement("[data-table=dt]", this));

        this.addEventListener("shown.bs.modal", (e) => {
            // Mostrar los contactos
            DtAddresses.reloadData(addresses.addresses);
        });

        this.addEventListener("click", async (e) => {
            const CntDtAddresses = e.target.closest("[data-table=dt]");
            const BtnAdd = e.target.closest("[name=add]");
            // const BtnCancel = e.target.closest("[name=cancel]");

            if (CntDtAddresses) {
                const BtnAddContact = e.target.closest("[name=btnAddContact]");
                const Main = e.target.closest("[name=main]");
                const Billing = e.target.closest("[name=billing]");
                const Edit = e.target.closest("[data-target=edit]");
                const Delete = e.target.closest("[data-target=delete]");

                if (BtnAddContact) {
                    address.address.id = 0;
                }

                if (Main) {
                    address.address.id = getData(Main);
                    setMain();
                    address.address.id = 0;
                }

                if (Billing) {
                    address.address.id = getData(Billing);
                    setBilling();
                    address.address.id = 0;
                }

                if (Edit) {
                    const ModalAddress = getElement(
                        '[data-bs-target^="#modalAddress_"]',
                        this
                    );
                    ModalAddress.click();
                    address.address.id = getData(Edit, "data-id");
                }

                if (Delete) {
                    address.address.id = getData(Delete, "data-id");
                    remove();
                    address.address.id = 0;
                    DtAddresses.reloadData(addresses.addresses);
                }
            }

            if (BtnAdd) {
                // Obtener el modal padre para obtener el formulario
                const MC = getElement(this.id.split("_")[1]);
                const FormCompany = getElement("[data-form=form]", MC);

                // Si existen direcciones codificar y enviar
                FormCompany.elements.addresses.value = b64Uri(
                    addresses.addresses
                );
            }
        });
    }
}

window.customElements.define("addresses-modal", AddressesModal, {
    extends: "div",
});
