import { dataAccounting } from "../../../../../../components/selectAccountingPlan";
import {
    alertHTML,
    b64Uri,
    bodyRequest,
    getData,
    getElement,
    requestOptions,
    resetSelect2Ajax,
    tryAsyncFetch,
} from "../../../../../../helpers";
import { Dt } from "../../../../../../plugins/datatable";

export class FindAccountingplanModal extends HTMLDivElement {
    #accounts = [];
    #dt = null;

    connectedCallback() {
        // console.log(this);
        this.addEvents();
        this.dt();
    }

    addEvents() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.account.addEventListener("aj:select2", (e) => {
            let ac = e.detail.data;
            ac.buttons = null;
            dataAccounting.accountingplans.push(ac.id);
            this.#accounts.push(ac);
            this.#dt.reloadData(this.#accounts);
            resetSelect2Ajax(Form.elements.account);
        });

        Form.addEventListener("click", async (e) => {
            const Target = e.target;

            if (Target.name == "save") {
                if (this.#accounts.length) {
                    bodyRequest.set("accounts", b64Uri(this.#accounts));
                    const Data = await tryAsyncFetch(
                        Form.action,
                        requestOptions
                    );
                    bodyRequest.delete("accounts");

                    if (Data.success) {
                        this.#accounts = [];
                        dataAccounting.accountingplans = [];
                        this.#dt.reloadData(this.#accounts);
                        alertHTML(Data.success, "success");
                        Form.elements.closeModal.click();
                    } else {
                        alertHTML(Data.error);
                    }
                } else {
                    alertHTML(getData(Target, "data-empty"));
                }
            }

            if (getData(Target, "data-target") == "delete") {
                // Eliminar el target
                this.#accounts = this.#accounts.filter(
                    (item) => item.id != getData(Target, "data-id")
                );

                // quitar del filtro del select
                dataAccounting.accountingplans =
                    dataAccounting.accountingplans.filter(
                        (item) => item != getData(Target, "data-id")
                    );

                // recargar la data
                this.#dt.reloadData(this.#accounts);
            }
        });
    }

    dt() {
        let configDt = {
            ordering: false,
            info: false,
            lengthChange: false,
            stateSave: false,
            pageLength: 5,
            searching: false,
        };

        configDt.columns = [
            {
                data: "name",
                render: (data, type, row) => `${row.number} - ${row.name}`,
            },
            {
                data: "buttons",
                width: "5%",
                class: "text-center",
                render: (data, type, row) => {
                    let buttons = `<i class="fas fa-trash-alt text-danger" role="button" data-target="delete" data-id="${row.id}"></i>`;
                    return buttons;
                },
            },
        ];

        this.#dt = new Dt(configDt);
        this.#dt.dataTable(getElement("[data-table=dtAccounts]", this));
    }
}

window.customElements.define(
    "findaccountingplan-modal",
    FindAccountingplanModal,
    {
        extends: "div",
    }
);
