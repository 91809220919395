import { setFocus, b64Uri, getElement } from "../../helpers";

class LoginModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const FormLogin = getElement("#formLogin", this);

        if (FormLogin) {
            const InputEmail = FormLogin.elements.email;
            const InputPassword = FormLogin.elements.password;
            const InputCheck = FormLogin.elements.rememberMe;
            const BtnLogin = FormLogin.elements.btnLogin;

            BtnLogin.addEventListener("click", () => {
                if (FormLogin.reportValidity()) {
                    if (InputCheck.checked) {
                        if (
                            InputEmail.value.trim() &&
                            InputPassword.value.trim()
                        ) {
                            let credentials = {
                                email: InputEmail.value,
                                password: InputPassword.value,
                            };

                            credentials = b64Uri(credentials);

                            if (credentials) {
                                localStorage.setItem("rememberMe", credentials);
                            }
                        }
                    } else {
                        localStorage.removeItem("rememberMe");
                    }

                    FormLogin.submit();
                }
            });

            if (localStorage.getItem("rememberMe")) {
                let credentials = JSON.parse(
                    b64Uri(localStorage.getItem("rememberMe"), "decode")
                );
                InputEmail.value = credentials.email;
                InputPassword.value = credentials.password;
                InputCheck.checked = true;
            }

            // End Login
            //
            // |||||||||||||||||||||||||||||||||||||||||||||||
            // || ----------------------------------------- ||
            // |||||||||||||||||||||||||||||||||||||||||||||||
            //
            // Start RestorePassword
            const PasswordRecovery = getElement("#modalPasswordRecovery", this);

            if (PasswordRecovery) {
                const BtnPasswordRecovery =
                    PasswordRecovery.elements.btnPasswordRecovery;

                BtnPasswordRecovery.addEventListener("click", () => {
                    if (PasswordRecovery.reportValidity()) {
                        PasswordRecovery.submit();
                    }
                });

                PasswordRecovery.addEventListener("shown.bs.modal", () => {
                    setFocus(PasswordRecovery.elements.email);
                });
            } else {
                console.warn("No existe el elemento 'modalPasswordRecovery'");
            }
            // End RestorePassword
        } else {
            console.warn("No existe el elemento 'formLogin'");
        }
    }
}

window.customElements.define("login-module", LoginModule, {
    extends: "section",
});
