import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    setSelect2Ajax,
    defaultSelect2,
    b64Uri,
    getJson,
    Management,
} from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectSaleInvoice extends HTMLSelectElement {
    async connectedCallback() {
        // let management = getData(this, "data-management");

        let configSelect = {
            ajax: {
                url: getData("saleInvoicesUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        management: Management,
                        limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const SS = await SD.selectDos(this);

        SS[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;
        });

        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (def.id) {
                // setSelect2Ajax(this, def.id, def.number + " - " + def.name);
                setSelect2Ajax(this, def.id, `${def.ref} (${def.cif})`);
            }
        }
        if (getData(this, "data-selected")) {
            defaultSelect2(
                this,
                getData(this, "data-selected"),
                "suppliersUrlSelect"
            );
        }
    }
}

window.customElements.define("select-saleinvoice", SelectSaleInvoice, {
    extends: "select",
});
