class ChangeCompany extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        this.addEventListener("aj:select2", (e) => {
            // Crear identificator
            if (e.target.name === "changeCompany") {
                let company = e.detail.data;
                let href = window.location.href.split("/");
                href[3] = company.slug;
                href = href.join("/");
                window.location.href = href;
            }
        });
    }
}

window.customElements.define("change-company", ChangeCompany, {
    extends: "div",
});
