import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectProduct extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("productsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);
        //SD.default("sociedad limitada", "entitiesUrlSelect");

        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            setSelect2Ajax(this, def.id, def.name);
        }
    }
}

window.customElements.define("select-product", SelectProduct, {
    extends: "select",
});
