import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    getJson,
    b64Uri,
    setSelect2Ajax,
    defaultSelect2,
} from "../helpers";

export const dataAccounting = {
    accountingplans: [],
    concat: [],
};

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectAccountingPlan extends HTMLSelectElement {
    async connectedCallback() {
        let isParent = this.getAttribute("data-isParent");
        let managementFilter = this.hasAttribute("data-not-filter-management");
        let accountGroup = this.getAttribute("data-account-group");
        let configSelect = {
            ajax: {
                url: getData("accountingplansUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    // Simular scroll infinito
                    let page = param.page || 1;
                    return {
                        _token: CSRF,
                        search: param.term,
                        accountingplans: dataAccounting.accountingplans,
                        isParent: isParent,
                        managementFilter: managementFilter,
                        accountGroup: accountGroup,
                        dataAccounting: JSON.stringify(dataAccounting),
                        page: page,
                    };
                },
                processResults: function (response, params) {
                    // Simular scroll infinito
                    params.page = params.page || 1;
                    let more =
                        params.page * response.limit < response.total_count;
                    return {
                        results: response.items,
                        pagination: {
                            more: more,
                        },
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        const SA = await SD.selectDos(this);

        SA[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            switch (getData(this, "data-filter")) {
                case "shared": {
                    let match = this.name.match(/\[(.*?)\]/); // en caso que el name sea compartido en el form ejemplo account['sales']
                    let name = match ? match[1] : this.name;
                    dataAccounting.concat[name] = Data.id;
                    dataAccounting.accountingplans = Object.values(
                        dataAccounting.concat
                    );

                    break;
                }
            }
        });

        // Código Carlos
        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (def?.id) {
                setSelect2Ajax(
                    this,
                    def.id,
                    def.number + " - " + def.name,
                    def
                );
            }
        }
        // Fin Código Carlos

        if (getData(this, "data-selected")) {
            defaultSelect2(
                this,
                getData(this, "data-selected"),
                "accountingplansUrlSelect"
            );
        }
    }
}

window.customElements.define("select-accountingplan", SelectAccountingPlan, {
    extends: "select",
});
