import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import {
    Management,
    b64Uri,
    cleanFloat,
    createForm,
    formatCurrency,
    getData,
    getElement,
    getJson,
} from "../../../../../helpers";

class OpenCloseModule extends HTMLElement {
    #detail = null;
    connectedCallback() {
        this.addEvents();
    }

    load(detail = null) {
        // ejecutar este método solo en el aj:startYear para evitar que se ejecute dos veces
        const Form = getElement("[data-form=form]", this);
        const Info = getElement("[data-target=info]", this);
        const Exists = getElement("[data-target=exists]", this);
        const OpenYear = getElement("[data-target=openYear]", this);
        const IptOpening = Array.from(Form.elements.type).find(
            (i) => i.value == "opening"
        );
        const IptClosing = Array.from(Form.elements.type).find(
            (i) => i.value == "closing"
        );
        const ParentOpening = IptOpening.parentElement.parentElement;
        const ParentClosing = IptClosing.parentElement.parentElement;
        OpenYear.classList.add("d-none");
        // Form.elements.open_year.checked = false;

        if (detail) {
            this.#detail = detail;
            // esto debe estar dentro de este if para que funcione correctamente
            if (detail.closing) {
                Exists.classList.remove("d-none");
                Exists.textContent = getData(Exists, "data-closing");
                OpenYear.classList.remove("d-none");
                // Form.elements.open_year.checked = true;
            }

            if (detail.opening) {
                Exists.classList.remove("d-none");
                Exists.textContent = getData(Exists, "data-opening");
                ParentClosing.classList.remove("d-none");
            } else {
                ParentClosing.classList.add("d-none");
            }

            if (detail.opening && detail.closing) {
                ParentClosing.classList.remove("d-none");
                Exists.textContent = getData(Exists, "data-opening-closing");
            }

            if (!detail.opening && !detail.closing) {
                Exists.classList.add("d-none");
            }
        }

        let startYear = parseInt(localStorage.getItem(Management.slug));
        let years = Management.years.map((obj) => obj.year);
        let minYear = Math.min(...years);
        let maxYear = Math.max(...years);

        Form.classList.remove("d-none");
        Info.classList.add("d-none");

        if (maxYear - startYear > 1) {
            Info.classList.remove("d-none");
            Form.classList.add("d-none");
        } else {
            IptOpening.checked = true;
            ParentOpening.classList.remove("d-none");

            if (maxYear - minYear > 1) {
                if (maxYear - startYear > 0) {
                    ParentOpening.classList.add("d-none");
                    IptClosing.checked = true;
                    // Exists.classList.add("d-none");
                }
            }
        }

        if (!ParentOpening.classList.contains("d-none")) {
            OpenYear.classList.add("d-none");
            // Form.elements.open_year.checked = false;
        }
    }

    addEvents() {
        const Tbody = getElement("[name=tbody]", this);
        let row = Tbody.lastElementChild.cloneNode(true);
        const Form = getElement("[data-form=form]", this);

        //Acciones
        this.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnSave = Target.closest("[name=save]");
            const IptType = Target.closest("[name=type]");
            const BtnCloseYear = Target.closest("[name=btnCloseYear]");
            const Exists = getElement("[data-target=exists]", this);
            const OpenYear = getElement("[data-target=openYear]", this);

            if (BtnSave && this.validate() & Form.reportValidity()) {
                // Preparar líneas
                this.prepareLines();
                Form.submit();
            }

            if (IptType) {
                OpenYear.classList.add("d-none");
                Exists.classList.add("d-none");
                // Form.elements.open_year.checked = false;

                if (IptType.value == "closing") {
                    OpenYear.classList.remove("d-none");
                    // Form.elements.open_year.checked = true;
                }

                if (this.#detail[IptType.value]) {
                    Exists.classList.remove("d-none");
                    Exists.textContent = getData(
                        Exists,
                        "data-" + IptType.value
                    );
                }
            }

            if (BtnCloseYear) {
                let dataForm = new FormData(Form);
                let data = Array.from(dataForm).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
                data.lines = b64Uri(false);
                createForm(Form.action, data);
            }
        });

        Form.elements.new_line?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);

            getElement("[name=amount]", row2).value = "";
            getElement("[name=accountingplan_id]", row2).removeAttribute(
                "data-selected"
            );

            Tbody.appendChild(row2);
        });

        this.addEventListener("change", (e) => {
            const Target = e.target;

            if (Target.classList.contains("calcTotal")) {
                this.total();
            }

            if (Target.name == "amount") {
                if (cleanFloat(Target.value) <= 0) {
                    Target.value = "";
                }
            }
        });

        Tbody.addEventListener("aj:select2", (e) => {
            const Target = e.target;
            const Data = e.detail.data;
            if (Target.name == "accountingplan_id") {
                // Identificar cada cuenta de fila para concatenar
                dataAccounting.concat[getData(Target, "data-select2-id")] =
                    Data.id;
                dataAccounting.accountingplans = Object.values(
                    dataAccounting.concat
                );

                // Setear debe o haber
                const Column =
                    Target.parentElement.parentElement.parentElement
                        .nextElementSibling.firstElementChild;

                const Group = Data.accountinggroup;

                // Pendiente revisar esto solo se toma en cuento el grupo
                if (Group) {
                    switch (Data.rule) {
                        case 1:
                            if (Group.increment == "debit") {
                                Column.value = "credit";
                            } else {
                                Column.value = "debit";
                            }
                            break;

                        default:
                            Column.value = Group.increment;
                            break;
                    }
                }
            }
        });

        Tbody.addEventListener("cl:deleteRow", (e) => {
            this.filterAccount();
            this.total();
        });

        Tbody.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnDiff = Target.closest("[name=btnDiff]");

            if (BtnDiff?.name == "btnDiff") {
                let total = this.total();
                const IptAmmout = BtnDiff.nextElementSibling;

                if (!IptAmmout.value.length) {
                    let t = Math.abs(total.total);

                    if (t > 0) {
                        IptAmmout.value = formatCurrency(t, "decimal");
                    }

                    this.total();
                }
            }
        });

        document.body.addEventListener("aj:startYear", (e) => {
            this.load(e.detail);
        });
    }

    filterAccount() {
        const Tbody = getElement("[name=tbody]", this);
        // Eliminar filtro de la cuenta eliminada
        dataAccounting.concat = [];

        Array.from(Tbody.children).forEach((row, key) => {
            const SelectAccount = getElement("[name=accountingplan_id]", row);
            const Data = getJson(
                b64Uri(
                    getData(SelectAccount, "data-data-selected") ??
                        getData(SelectAccount, "data-default"),
                    "decode"
                )
            );

            if (!Data?.error) {
                dataAccounting.concat["accountingplan_" + key] = Data.id;
            }
        });

        dataAccounting.accountingplans = Object.values(dataAccounting.concat);
    }

    total() {
        const Tbody = getElement("[name=tbody]", this);
        const IptTotal =
            Tbody.nextElementSibling.firstElementChild.querySelector(
                "[name=total]"
            );
        const Label =
            Tbody.nextElementSibling.firstElementChild.querySelector("span");

        let total = { debit: 0, credit: 0, total: 0 };

        Array.from(Tbody.children).forEach((row) => {
            const IptColumn = getElement("[name=column]", row);
            const IptAmount = getElement("[name=amount]", row);

            total[IptColumn.value] += cleanFloat(IptAmount.value);
        });

        total.total = total.debit - total.credit;
        IptTotal.value = formatCurrency(Math.abs(total.total), "decimal");

        if (total.total > 0) {
            Label.textContent = getData(Label, "data-sd");
        } else if (total.total < 0) {
            Label.textContent = getData(Label, "data-sc");
        } else {
            Label.textContent = getData(Label, "data-s0");
        }

        return total;
    }

    validate() {
        const Form = getElement("[data-form=form]", this);
        let total = this.total();
        let flag = true;

        Form.elements.total.setCustomValidity("");

        if (total.total != 0) {
            flag = false;
            Form.elements.total.setCustomValidity(
                getData(Form.elements.total, "data-custom-validity")
            );
        }

        return flag;
    }

    prepareLines() {
        const Tbody = getElement("[name=tbody]", this);
        const Form = getElement("[data-form=form]", this);
        let lines = [];

        Array.from(Tbody.children).forEach((row) => {
            const Account = getElement("[name=accountingplan_id]", row);
            const Column = getElement("[name=column]", row);
            const Amount = getElement("[name=amount]", row);

            // Validación extra
            if (Account.value && Column.value && Amount.value) {
                let line = {
                    accountingplan_id: Account.value,
                    accountingplan_data: b64Uri(
                        getData(Account, "data-data-selected"),
                        "decode"
                    ),
                    accounting_column: Column.value,
                    amount: cleanFloat(Amount.value),
                };

                lines.push(line);
            }
        });

        Form.elements.lines.value = b64Uri(lines);
    }
}

window.customElements.define("openclose-module", OpenCloseModule, {
    extends: "section",
});
