import {
    alertHTML,
    b64Uri,
    formatTel,
    getData,
    getElement,
    getJson,
    validateEmail,
    validateTel,
    readTranslations
} from "../../../../../helpers";

// Variables y Constantes
export let contacts = { contacts: new Array() };
export let contact = { contact: { id: 0 } };

const load = (modalContact) => {
    // console.info(modalContact);
    const FormContact = getElement("[data-form=formContact]", modalContact);
    const BtnAdd = getElement("[name=add]", modalContact);
    const BtnCancel = getElement("[name=cancel]", modalContact);

    // Asignar evento para comprobar elementos necesario para evitar conflicto enla validación
    modalContact.addEventListener("input", (e) => {
        const InputEmail = e.target.closest("[name=email]");
        const InputTel = e.target.closest("[name=tel]");

        if (InputEmail) {
            validateEmail(InputEmail);
        }

        if (InputTel) {
            validateTel(InputTel);
        }
    });

    BtnAdd.addEventListener("click", async () => {
        // Validar formulario
        if (validate(FormContact)) {
            let success = false;

            // Si no hay contacto editar de lo contrario agregar
            if (contact.contact.id) {
                success = await edit(FormContact);
            } else {
                success = await add(FormContact);
            }

            // Cerrar el formulario
            if (success) {
                BtnCancel.click();
            }
        }
    });

    // Al cerrar el modal devolver el id de contacto a 0
    BtnCancel.addEventListener("click", () => {
        contact.contact.id = 0;
    });

    // Se ejecuta inmediatamente que se lanza el modal
    modalContact.addEventListener("show.bs.modal", (e) => {
        if (getData(e.relatedTarget, "name") === "btnAddContact") {
            // Si el modal fue lanzado para agregar contacto, limpiar sus valores
            FormContact.reset();
        }
    });

    // Esperar que cargue el modal necesario para que contact tenga su valor
    modalContact.addEventListener("shown.bs.modal", (e) => {
        const Contact = get();

        // Al editar un contacto evitar que se restaure el formulario cuando se cambia el formato de teléfono
        if (
            Contact &&
            getData(e.relatedTarget, "name") !== "btnSelectCountry"
        ) {
            FormContact.elements.name.value = Contact.name;
            FormContact.elements.email.value = Contact.email?.email ?? "";
            
            if (Contact.tel?.tel) {
                // preformatear al valor del teléfono para evitar perdida de información
                formatTel(
                    Contact.tel.country,
                    FormContact.elements.tel.previousElementSibling
                        .firstElementChild
                );
                FormContact.elements.tel.setAttribute(
                    "data-country",
                    b64Uri(Contact.tel.country)
                );
                FormContact.elements.tel.value = Contact.tel.tel;
            }
        }
    });
};

/**
 * Validar los campos del formulario
 *
 * @param {HTMLFormElement} form Elemento de tipo form
 * @returns
 */
const validate = (form) => {
    let validated = false;

    if (
        form.reportValidity() &&
        validateEmail(form.elements.email) &&
        validateTel(form.elements.tel)
    ) {
        validated = true;
    }

    return validated;
};

/**
 * Buscar un item dentro del array de objetos
 *
 * @param {object} item Item objetivo
 * @returns
 */
const search = (item) => {
    return contacts.contacts.find(
        (c) =>
            c.name === item.name &&
            c.email?.email === item.email?.email &&
            c.tel?.tel === item.tel?.tel
    );
};

/**
 * Obtener el contacto
 *
 * @returns
 */
const get = () => {
    return contacts.contacts.find((c) => c.id === parseInt(contact.contact.id));
};

/**
 * Agregar un contacto a la lista
 *
 * @param {HTMLFormElement} form Elemento de tipo form
 * @returns
 */
const add = async (form) => {
    const Contact = {
        id: contacts.contacts.length + 1,
        name: form.elements.name.value,
        email: { email: form.elements.email.value },
        tel: {
            tel: form.elements.tel.value,
            country: getJson(
                b64Uri(getData(form.elements.tel, "data-country"), "decode")
            ),
        },
        main: 0,
        buttons: null,
    };

    let found = search(Contact);

    if (found) {
        const Translations = await readTranslations();
        alertHTML(Translations["view.generic.contact_exists"], "error");

        return false;
    } else {
        if (!contacts.contacts.length) {
            Contact.main = 1;
        }

        contacts.contacts.push(Contact);
        form.reset();

        return true;
    }
};

/**
 * Editar un contacto de la lista
 *
 * @param {HTMLFormElement} form Elemento de tipo form
 */
const edit = async (form) => {
    const Contact = get();

    let found = search({
        name: form.elements.name.value,
        email: { email: form.elements.email.value },
        tel: { tel: form.elements.tel.value },
    });

    if (found && found.id !== Contact.id) {
        const Translations = await readTranslations();
        alertHTML(Translations["view.generic.contact_exists"], "error");

        return false;
    } else {
        Contact.name = form.elements.name.value;

        if (Contact.email?.email) {
            Contact.email.email = form.elements.email.value;
        } else {
            Contact.email = { email: form.elements.email.value };
        }

        if (Contact.tel?.tel) {
            Contact.tel.tel = form.elements.tel.value;
            Contact.tel.country = getJson(
                b64Uri(getData(form.elements.tel, "data-country"), "decode")
            );
        } else {
            Contact.tel = {
                tel: form.elements.tel.value,
                country: getJson(
                    b64Uri(getData(form.elements.tel, "data-country"), "decode")
                ),
            };
        }

        form.reset();

        return true;
    }
};

/**
 * Eliminar un elemento del array
 */
export const remove = () => {
    const Contact = get();
    const Index = contacts.contacts.findIndex((c) => c.id === Contact.id);
    contacts.contacts.splice(Index, 1);

    if (Contact.main && contacts.contacts.length) {
        contacts.contacts[0].main = Contact.main;
    }

    contacts.contacts.forEach((item, index) => {
        item.id = index + 1;
    });
};

/**
 * Establecer un elemento como principal
 */
export const setMain = () => {
    contacts.contacts.forEach((c) => (c.main = 0));
    const Contact = get();
    Contact.main = 1;
};

class ContactModal extends HTMLDivElement {
    connectedCallback() {
        load(this);
    }
}

window.customElements.define("contact-modal", ContactModal, {
    extends: "div",
});
