import {
    bodyRequest,
    getData,
    getElement,
    requestOptions,
} from "../../../../../../helpers";

export class DocumentsTab extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        this.addEventListener("click", async (e) => {
            e.preventDefault();
            const T = e.target;
            const PL = T.closest(".page-link");
            const BC = T.closest("[data-breadcrumbs]");

            // Paginación
            if (PL) {
                let url = getData(PL, "href");

                if (url) {
                    this.loadDocuments(url);
                }
            }

            // Migas de pan
            if (BC) {
                this.enterDirectory(BC);
            }
        });

        this.addEventListener("dblclick", async (e) => {
            e.preventDefault();
            const T = e.target;
            const D = T.closest("[data-type]");

            // Directorio
            if (D) {
                if (getData(D, "data-type") === "file") {
                    console.log("Descargar archivo");
                } else {
                    this.enterDirectory(D);
                }
            }
        });
    }

    async loadDocuments(url) {
        let request = await fetch(url, requestOptions);
        let response = await request.text();
        const Doc = new DOMParser().parseFromString(response, "text/html");
        const New = getElement('[is="' + getData(this, "is") + '"]', Doc);
        this.parentNode.replaceChild(New, this);
    }

    enterDirectory(target) {
        bodyRequest.set("directory_id", getData(target, "data-directory_id"));
        bodyRequest.set("depth", getData(target, "data-depth"));
        this.loadDocuments(window.location.href);
    }
}

window.customElements.define("documents-tab", DocumentsTab, {
    extends: "div",
});
