import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    setSelect2Ajax,
    setSelect2,
    defaultSelect2,
    getJson,
    b64Uri,
} from "../helpers";
// import ucwords from "ucwords";

export const dataTax = { taxes: [] };

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectTax extends HTMLSelectElement {
    connectedCallback() {
        let type = getData(this, "data-type");
        let filter = !this.hasAttribute("data-not-filter");
        let configSelect = {
            ajax: {
                url: getData("taxesUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: (param) => {
                    type = getData(this, "data-type");
                    return {
                        _token: CSRF,
                        search: param.term,
                        taxes: dataTax.taxes,
                        filter: filter,
                        type: type,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);

        if (getData(this, "data-default")) {
            let def = getJson(
                b64Uri(getData(this, "data-default"), "decode", false)
            );
            // Aplico solución por una codificaciñón que usa Carlos en las líneas de factura de compra
            if (def.error) {
                def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            }
            if (def.id) {
                setSelect2Ajax(this, def.id, def.description);
            }
        }
    }
}

window.customElements.define("select-tax", SelectTax, {
    extends: "select",
});
