import { getElement, resetForm } from "../../../../../../helpers";
export class AccountinggroupModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        const FormAccountinggroup = getElement("[data-form=form]", this);

        FormAccountinggroup.elements.save.addEventListener("click", () => {
            if (FormAccountinggroup.reportValidity()) {
                FormAccountinggroup.submit();
            }
        });
    }

    static create(modal, url, title) {
        const FormAccountinggroup = getElement(
            "[data-form=form]",
            getElement(modal)
        );
        resetForm(FormAccountinggroup, title, url);
    }

    static edit(modal, url, title, data) {
        data = JSON.parse(atob(data));
        const form = getElement("[data-form=form]", getElement(modal));
        resetForm(form, title, url);
        form.elements.name.value = data.name;
        form.elements.rule.value = data.rule;
        form.elements.increment.value = data.increment;
    }
}

window.customElements.define("accountinggroup-modal", AccountinggroupModal, {
    extends: "div",
});
