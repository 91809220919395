import {
    b64Uri,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class TaxSupplier extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);

        setSelect2Ajax(Form.elements.tax_id, data.id, data.description);
    }
}

window.customElements.define("tax-supplier", TaxSupplier, {
    extends: "div",
});
