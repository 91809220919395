import { getData } from "../../../../helpers";
import { SupplierModal as Supplier } from "./modals/store";

class ShowSupplierModule extends HTMLElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const BtnEditCompany = e.target.closest(".target.edit");

            // Editar Empresa
            if (BtnEditCompany) {
                Supplier.edit(
                    getData(BtnEditCompany, "data-bs-target"),
                    getData(BtnEditCompany, "title"),
                    getData(BtnEditCompany, "data-url"),
                    getData(BtnEditCompany, "data-data")
                );
            }
        });
    }
}

window.customElements.define("showsupplier-module", ShowSupplierModule, {
    extends: "section",
});
