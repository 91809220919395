import { confirmDelete, getData } from "../helpers";

const load = (element) => {
    // console.log(element);

    element.addEventListener("click", () => {
        confirmDelete(
            getData(element, "title"),
            getData(element, "data-text"),
            getData(element, "data-url"),
            getData(element, "data-pay") ?? getData(element, "data-receipt"),
            getData(element, "data-cancel")
        );
    });
};

class PayedButton extends HTMLButtonElement {
    connectedCallback() {
        load(this);
    }
}

window.customElements.define("payed-button", PayedButton, {
    extends: "button",
});
