import { getData } from "../helpers";
import { AccountingplanModal } from "../views/settings/settings/accounting/accountingplans/modals/store";

class AccountingplanButton extends HTMLButtonElement {
    connectedCallback() {
        this.test();
        this.load();
        this.events();
    }

    test() {
        // No borrar este método, pero sí su contenido
    }

    load() {
        //
    }

    events() {
        this.addEventListener("click", () => {
            AccountingplanModal.create(
                getData(this, "data-bs-target"),
                getData(this, "data-url"),
                getData(this, "title"),
                getData(this, "data-message")
            );
        });
    }
}

window.customElements.define("accountingplan-button", AccountingplanButton, {
    extends: "button",
});
