import dom, { Fragment } from "jsx-render";
import {
    b64Uri,
    cleanFloat,
    formatCurrency,
    getData,
    getElement,
    getJson,
    resetForm,
    Management,
    DecimalLength,
} from "../../../../../helpers";
import { dataBank } from "../../../../../components/selectBank";
import moment from "moment";
export class ReceiptReturnModal extends HTMLDivElement {
    constructor(element = null) {
        super(); // Llama al constructor de HTMLDivElement

        if (element) {
            // Heredar de la clase al elemento del DOM
            Object.setPrototypeOf(element, ReceiptReturnModal.prototype);
            return element;
        }
    }

    connectedCallback() {
        this.addEvents();
    }

    calcTotal() {
        const IptsReceipt = this.querySelectorAll(".receipt");
        let total = Array.from(IptsReceipt).reduce((acc, item) => {
            acc += cleanFloat(item.value == "-" ? 0 : item.value);
            return acc;
        }, 0);

        const Amount = getElement("[name=amount]", this);
        Amount.value = formatCurrency(total.toFixed(DecimalLength), "decimal");
    }

    addEvents() {
        const Form = getElement("[data-form=form]", this);

        this.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.hasAttribute("data-total")) {
                let total = getData(Target, "data-total");
                const IptTotalLine = Target.previousElementSibling;
                IptTotalLine.value = total;
                this.calcTotal();
            }

            if (Target.name == "save") {
                const Form = getElement("[data-form=form]", this);

                if (Form.reportValidity()) {
                    Form.submit();
                }
            }
        });

        this.addEventListener("input", (e) => {
            const Target = e.target;

            if (Target.hasAttribute("data-number")) {
                let totalLine = cleanFloat(
                    getData(Target.nextElementSibling, "data-total")
                );
                let val = cleanFloat(Target.value);

                if (val > totalLine) {
                    Target.value = formatCurrency(totalLine, "decimal");
                }

                this.calcTotal();
            }
        });

        this.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            Form.elements.bank_id.removeAttribute("required");

            if (Data.need_bank) {
                Form.elements.bank_id.setAttribute("required", "");
            }
        });
    }

    create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        dataBank.companies = [Management.company_id];
        const TBody = getElement("[data-target=tbody]", Form);
        TBody.innerHTML = "";

        const IptsChecked = Array.from(
            document.querySelectorAll("[name=check]")
        ).filter((item) => item.checked);

        IptsChecked.forEach((item) => {
            let data = getJson(b64Uri(getData(item, "data-data"), "decode"));
            let date = moment(data.date_invoice);
            let totalLine = data.lines.reduce((acc, item) => {
                acc += parseFloat(item.total_line);
                return acc;
            }, 0);

            let received = data.receipts.reduce((acc, item) => {
                acc += parseFloat(item.pivot.amount);
                return acc;
            }, 0);

            let total = formatCurrency(totalLine - received);

            const Tr = (
                <tr>
                    <td class="text-center">{data.ref}</td>
                    <td class="text-center">
                        {date.format(getData(Form, "data-date-format"))}
                    </td>
                    <td class="text-end">{total}</td>
                    <td class="text-center">
                        <div class="input-group input-group-sm mt-1">
                            <input
                                type="text"
                                class="form-control form-control-sm text-end receipt"
                                data-number
                                data-signal="+"
                                name={`invoice[${data.id}]`}
                                required
                            />
                            <i
                                class="input-group-text fa-solid fa-money-bill-transfer pt-2"
                                data-total={formatCurrency(
                                    totalLine - received,
                                    "decimal"
                                )}
                            ></i>
                        </div>
                    </td>
                </tr>
            );

            TBody.appendChild(Tr);
        });

        this.calcTotal();
    }

    // static edit(modal, title, url, data) {
    //     data = JSON.parse(atob(data));
    //     const form = getElement("[data-form=form]", getElement(modal));
    //     resetForm(form, title, url);
    //     form.elements.name.value = data.name;
    //     form.elements.number.value = data.number;
    //     if (data.accountinggroup)
    //         setSelect2Ajax(
    //             form.elements.accountinggroup_id,
    //             data.accountinggroup.id,
    //             data.accountinggroup.name
    //         );
    //     if (data.accountingplan)
    //         setSelect2Ajax(
    //             form.elements.accountingplan_id,
    //             data.accountingplan.id,
    //             data.accountingplan.number + " - " + data.accountingplan.name
    //         );
    //     if (data.journal)
    //         setSelect2Ajax(
    //             form.elements.journal_id,
    //             data.journal.id,
    //             data.journal.code + " - " + data.journal.name
    //         );
    // }
}

window.customElements.define("receiptreturn-modal", ReceiptReturnModal, {
    extends: "div",
});
