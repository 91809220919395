import $ from "jquery/dist/jquery";
import "metismenu/dist/metisMenu";

class MenuModule extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.info(this);
        this.addEventListener("click", (e) => {
            const Target = e.target.closest("li");

            if (Target?.getAttribute("module")) {
                let option = {
                    module: Target.getAttribute("module"),
                    parent: Target.getAttribute("parent"),
                };

                localStorage.setItem("keepMenu", JSON.stringify(option));
            }
        });

        this.init();
    }

    /**
     * Mantener visible la opción seleccionada en el menú
     *
     * @param {boolean} stay parámetro para diferenciar cuando se autollama a sí misma
     */
    keepMenu(stay = false) {
        const CtnMenu = document.querySelector("[is='menu-module']");
        let option = localStorage.getItem("keepMenu");
        let pageUrl = window.location.href.replace(/#$/, "");
        pageUrl = pageUrl.replace(/\/$/, "");

        pageUrl = pageUrl.replace(/\?/, "??"); // reemplazar el primer ? por ?? para evitar conflicto de split
        pageUrl = pageUrl.split("??");
        if (pageUrl.length > 1) {
            pageUrl.pop();
        }
        pageUrl = pageUrl.join("");

        const FindTarget = CtnMenu.querySelector("[href='" + pageUrl + "']");
        FindTarget?.parentElement.click();

        if (option) {
            option = JSON.parse(option);
            const Group = document.querySelector(
                "[group=" + option.parent + "]"
            );
            const Module = document.querySelector(
                "[module=" + option.module + "]"
            );

            if (Module) {
                let url = Module.firstElementChild.href;
                url = url.replace(/\/$/, "");

                if (stay) {
                    this.stayBack(CtnMenu, pageUrl);
                } else {
                    if (pageUrl === url) {
                        this.activeMenu(Module, Group);
                    } else {
                        const FindTarget = CtnMenu.querySelector(
                            "[href='" + pageUrl + "']"
                        );
                        FindTarget?.parentElement.click();

                        if (FindTarget) {
                            this.keepMenu();
                        } else {
                            this.keepMenu(true);
                        }
                    }
                }
            } else {
                if (FindTarget) {
                    this.keepMenu();
                } else {
                    this.stayBack(CtnMenu, pageUrl);
                }
            }
        } else {
            if (FindTarget) {
                this.keepMenu();
            } else {
                this.stayBack(CtnMenu, pageUrl);
            }
        }
    }

    /**
     * Aplicar las clases para mostrar el menú
     *
     * @param {HTMLElement} module Elemento que debe mostrarse
     * @param {HTMLElement} group Elemento que contiene el elemento que debe mostrarse
     */
    activeMenu(module, group) {
        module?.classList.add("mm-active");

        if (group) {
            group.classList.add("mm-active");
            const Cnt = group.querySelector(".nav-second-level");
            Cnt.classList.add("mm-show");
            let parentGroup = group.getAttribute("parent");
            const Parent = document.querySelector(
                "[group='" + parentGroup + "']"
            );

            if (Parent) {
                this.activeMenu(null, Parent);
            }
        }
    }

    /**
     * Automostrar el menú en base a la url que se encuentra
     *
     * @param {HTMLElement} ctnmenu
     * @param {string} url
     */
    stayBack(ctnmenu, url) {
        url = url.replace(/\/$/, "");
        let splitUrl = url.split("/");
        splitUrl.pop();
        splitUrl = splitUrl.join("/");
        const FindTarget = ctnmenu.querySelector("[href='" + splitUrl + "']");

        if (FindTarget) {
            FindTarget.parentElement.click();
            let option = localStorage.getItem("keepMenu");
            option = JSON.parse(option);
            const Group = document.querySelector(
                "[group=" + option.parent + "]"
            );
            const Module = document.querySelector(
                "[module=" + option.module + "]"
            );
            this.activeMenu(Module, Group);
        } else {
            this.stayBack(ctnmenu, splitUrl);
        }
    }

    initMetisMenu() {
        //metis menu
        $(".metismenu").metisMenu();
        $(window).resize(() => this.initEnlarge());
    }

    initLeftMenuCollapse() {
        // Left menu collapse
        $(".button-menu-mobile").on("click", (e) => {
            e.preventDefault();
            $("body").toggleClass("enlarge-menu");
        });
    }

    initEnlarge() {
        if ($(window).width() < 1300) {
            $("body").addClass("enlarge-menu");
        } else {
            $("body").removeClass("enlarge-menu");
        }
    }

    init() {
        this.initMetisMenu();
        this.initLeftMenuCollapse();
        this.initEnlarge();
        this.keepMenu();
    }
}

window.customElements.define("menu-module", MenuModule, {
    extends: "div",
});
