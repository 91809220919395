import { b64Uri, getElement, getJson, resetForm } from "../../../../../helpers";

export class DirectoryModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.name;
    }
}

window.customElements.define("directory-modal", DirectoryModal, {
    extends: "div",
});
