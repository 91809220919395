import { CSRF, getData, getElement } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";

class ViewBalanceModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        const BtnAdd = getElement("[name=add]", this); // Identificar el botón para crear
        const Form = getElement("[name=formline]", this);

        BtnAdd.addEventListener("click",(e) => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
        
    }
}

window.customElements.define("viewbalance-module", ViewBalanceModule, {
    extends: "section",
});
