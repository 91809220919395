import { SelectDos } from "../plugins/select2";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectSimple extends HTMLSelectElement {
    connectedCallback() {
        const Options = this.querySelectorAll("option");
        let data = [];
        let df = {}; // opción por defecto

        Options.forEach((element) => {
            data.push({
                id: element.value,
                text: element.textContent.trim(),
            });

            if (element.selected) {
                df.id = element.value;
                df.text = element.textContent.trim();
            }
        });

        let configSelect = {
            ajax: {
                transport: (params, success, failure) => {
                    // Obtiene la consulta de búsqueda
                    const search = params.data.term?.toLowerCase();

                    // Filtra los datos basándose en la consulta de búsqueda
                    const filteredData = data.filter((option) =>
                        option.text.toLowerCase().includes(search)
                    );

                    if (search) {
                        success({ results: filteredData });
                    } else {
                        success({ results: data });
                    }
                },
                processResults: (data) => {
                    return {
                        results: data.results,
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);
        SD.simpleDefault(df);
    }
}

window.customElements.define("select-simple", SelectSimple, {
    extends: "select",
});
