import { Dt } from "../../../../plugins/datatable";
import { getData, CSRF, getElement } from "../../../../helpers";
import { CompanyModal as Company } from "./modals/store";
import tippy from "tippy.js";

class CompanyModule extends HTMLElement {
    connectedCallback() {
        this.load();
        this.test();
    }

    test() {
        // Ese método sirve solo para hacer pruebas
        // Borrar o comentar su contenido pero no el método
        // const BtnNew = getElement("[name=new]", this);
        // BtnNew.click();
    }

    load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        // Eventos
        BtnNew?.addEventListener("click", () => {
            Company.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "title"),
                getData(BtnNew, "data-url")
            );
        });

        DtElement.addEventListener("click", (e) => {
            const Target = e.target.closest(".target");

            if (Target) {
                if (Target.classList.contains("edit")) {
                    Company.edit(
                        getData(Target, "data-bs-target"),
                        getData(Target, "title"),
                        getData(Target, "data-url"),
                        getData(Target, "data-data")
                    );
                }
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            paging: false,
        };

        configDt.ajax = {
            url: getData("companiesUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            {
                data: "logo",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            { data: "name" },
            { data: "cif", width: "7%" },
            {
                data: "contact",
                width: "10%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            tippy(".tippy-tooltips");
        };

        const DtCompanies = new Dt(configDt);
        DtCompanies.dataTable(DtElement);
        // End DataTable
    }
}

window.customElements.define("company-module", CompanyModule, {
    extends: "section",
});
