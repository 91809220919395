import { Modal, getInstance } from "bootstrap";
import {
    alertHTML,
    confirmDelete,
    formatCurrency,
    getData,
    getElement,
} from "../helpers";
import { ExportBalancesModal } from "../views/admin/accounting/modals/exportBalances";

class ExportBalancesButton extends HTMLButtonElement {
    connectedCallback() {
        this.load(this);
    }

    load = (element) => {
        const modal = ExportBalancesModal.create(
            getData(element, "data-bs-target"),
            getData(element, "data-url"),
            getData(element, "data-pay"),
            getData(element, "data-management")
        );
        element.addEventListener("click", () => {});
    };
}

window.customElements.define("exportbalances-button", ExportBalancesButton, {
    extends: "button",
});
