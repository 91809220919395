import { b64Uri, getElement, getJson, resetForm, setSelect2Ajax } from "../../../../../helpers";

export class LanguageModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.info(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));

        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));

        resetForm(Form, title, url);

        Form.elements.language.value = data.language;

        const S2 = getElement("select", Form);
        setSelect2Ajax(S2, data.lang.id, data.lang.alpha2, data.lang);
    }
}

window.customElements.define("language-modal", LanguageModal, {
    extends: "div",
});
