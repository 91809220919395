import { getData } from "../../../../helpers";
import { CustomerModal as Customer } from "./modals/store";

class ShowCustomerModule extends HTMLElement {
    connectedCallback() {
        // console.log(this);
        this.addEvents();
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const BtnEditCompany = e.target.closest(".target.edit");

            // Editar Empresa
            if (BtnEditCompany) {
                Customer.edit(
                    getData(BtnEditCompany, "data-bs-target"),
                    getData(BtnEditCompany, "title"),
                    getData(BtnEditCompany, "data-url"),
                    getData(BtnEditCompany, "data-data")
                );
            }
        });
    }
}

window.customElements.define("showcustomer-module", ShowCustomerModule, {
    extends: "section",
});
