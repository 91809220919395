import {
    b64Uri,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
} from "../../../../helpers";
import { dataBank } from "../../../../components/selectBank";
export class ExportBalancesModal extends HTMLDivElement {
    connectedCallback() {
        const FormExport = getElement("[data-form=form]", this);

        FormExport.elements.save.addEventListener("click", () => {
            FormExport.submit();
        });
    }

    static create(modal, url, title, management) {
        management = getJson(b64Uri(management, "decode"));
        const FormPayment = getElement("[data-form=form]", getElement(modal));
        resetForm(FormPayment, title, url);
        //data = JSON.parse(atob(data));
        dataBank.companies = [1];
    }

    static edit(modal, url, title, data) {
        data = JSON.parse(atob(data));
        const form = getElement("[data-form=form]", getElement(modal));
        resetForm(form, title, url);
        form.elements.name.value = data.name;
        form.elements.number.value = data.number;
        if (data.accountinggroup)
            setSelect2Ajax(
                form.elements.accountinggroup_id,
                data.accountinggroup.id,
                data.accountinggroup.name
            );
        if (data.accountingplan)
            setSelect2Ajax(
                form.elements.accountingplan_id,
                data.accountingplan.id,
                data.accountingplan.number + " - " + data.accountingplan.name
            );
        if (data.journal)
            setSelect2Ajax(
                form.elements.journal_id,
                data.journal.id,
                data.journal.code + " - " + data.journal.name
            );
    }
}

window.customElements.define("exportbalances-modal", ExportBalancesModal, {
    extends: "div",
});
