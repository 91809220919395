import dom, { Fragment } from "jsx-render";
import {
    bodyRequest,
    getData,
    getJson,
    formatTel,
    requestOptions,
    b64Uri,
    getElement,
    setSelect2Ajax,
} from "../helpers";

/**
 * Componente para mostrar el input de tipo password necesario Bootstrap5 y FontAwesome6,
 * otorgando la funcionalidad de mostrar y ocultar la constraseña
 *
 * @param {JSON} custom-attributes objeto convertido a string
 * @return {HTMLDivElement} estructura HTML de Bootstrap 5 para input group
 */
export class InputTel extends HTMLDivElement {
    #customAttributes = {
        input: {
            type: "text",
            class: "form-control form-control-sm",
            name: "tel",
        },
        img: {
            src: "/images/flags-png/es.png",
            alt: "...",
        },
        span: {
            class: "input-group-text",
            role: "button",
        },
    };

    constructor() {
        super();
    }

    connectedCallback() {
        let attributes = this.getAttribute("custom-attributes");

        if (attributes) {
            attributes = getJson(attributes);

            this.#customAttributes.input = {
                ...this.#customAttributes.input,
                ...attributes.input,
            };

            this.#customAttributes.img = {
                ...this.#customAttributes.img,
                ...attributes.img,
            };

            this.#customAttributes.span = {
                ...this.#customAttributes.span,
                ...attributes.span,
            };
        }

        this.appendChild(
            <>
                <span {...this.#customAttributes.span}>
                    <img {...this.#customAttributes.img} />
                </span>
                <input {...this.#customAttributes.input} />
            </>
        );

        this.firstElementChild.firstElementChild.style.width = "20px";

        this.defaultCountry();
        this.addClickEvent();
    }

    defaultCountry() {
        bodyRequest.set("search", "españa");
        fetch(getData("countriesUrlSelect"), requestOptions)
            .then((response) => response.json())
            .then((data) => {
                const Flag = this.firstElementChild.firstElementChild;
                this.firstElementChild.nextElementSibling.setAttribute(
                    "data-country",
                    b64Uri(data[0])
                );
                formatTel(data[0], Flag);
            })
            .catch((error) => console.log(error));
    }

    addClickEvent() {
        this.addEventListener("click", (e) => {
            const Flag = e.target.closest("span");
            if (Flag) {
                const Country = getJson(
                    b64Uri(
                        getData(Flag.nextElementSibling, "data-country"),
                        "decode"
                    )
                );

                const MC = getElement(getData(Flag, "data-bs-target"));
                const Select2 = getElement("[name=country]", MC);
                setSelect2Ajax(Select2, Country.id, Country.country, Country);
            }
        });
    }
}

window.customElements.define("input-tel", InputTel, {
    extends: "div",
});
