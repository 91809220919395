import { getData, getElement, tabs } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { CompanyModal as Company } from "./modals/store";
import { BankModal as Bank } from "../banks/modals/store";
import { DirectoryModal } from "../directories/modals/store";

class ShowCompanyModule extends HTMLElement {
    connectedCallback() {
        const DtAddresses = getElement("[data-table=dtAddresses]", this);
        const DtBanks = getElement("[data-table=dtBanks]", this);

        this.addEventListener("click", (e) => {
            const BtnEditCompany = e.target.closest(".target.edit");
            const BtnAddBank = e.target.closest("[name=btnAddBank]");
            const BtnEditBank = e.target.closest("[name=btnEditBank]");
            const Tab = e.target.closest("[data-bs-toggle=pill]");
            const NewDirectory = e.target.closest("[data-target=newDirectory]");

            // Editar Empresa
            if (BtnEditCompany) {
                Company.edit(
                    getData(BtnEditCompany, "data-bs-target"),
                    getData(BtnEditCompany, "title"),
                    getData(BtnEditCompany, "data-url"),
                    getData(BtnEditCompany, "data-data")
                );
            }

            // Agregar Banco
            if (BtnAddBank) {
                Bank.create(
                    getData(BtnAddBank, "data-bs-target"),
                    getData(BtnAddBank, "title"),
                    getData(BtnAddBank, "data-url")
                );
            }

            // Editar Banco
            if (BtnEditBank) {
                Bank.edit(
                    getData(BtnEditBank, "data-bs-target"),
                    getData(BtnEditBank, "title"),
                    getData(BtnEditBank, "data-url"),
                    getData(BtnEditBank, "data-data")
                );
            }

            // Recordar Tab
            if (Tab) {
                tabs(Tab);
            }

            // Nuevo Directorio
            if (NewDirectory) {
                DirectoryModal.create(
                    getData(NewDirectory, "data-bs-target"),
                    getData(NewDirectory, "title"),
                    getData(NewDirectory, "data-url")
                );
            }
        });

        // Start DataTable
        let configDt = {
            pageLength: 3,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 2, 3],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtA = new Dt(configDt);
        DtA.dataTable(DtAddresses);
        // End DataTable

        // Start DataTable
        configDt = {
            pageLength: 4,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 6],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtB = new Dt(configDt);
        DtB.dataTable(DtBanks);
        // End DataTable
    }
}

window.customElements.define("showcompany-module", ShowCompanyModule, {
    extends: "section",
});
