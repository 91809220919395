import { Dt } from "../../../../plugins/datatable";
import {
    getData,
    CSRF,
    bodyRequest,
    requestOptions,
    alertHTML,
    reloadSection,
    getElement,
    readTranslations,
} from "../../../../helpers";
import { LanguageModal as Language } from "./modals/store";

/**
 * Leer el archivo de idiomas seleccionado
 *
 */

class LanguageModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear
        const DtElement = getElement("[data-table=dt]", this);

        // Eventos
        BtnNew.addEventListener("click", () => {
            Language.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "title"),
                getData(BtnNew, "data-url")
            );
        });

        DtElement.addEventListener("click", (e) => {
            const Target = e.target.closest(".target");

            if (Target) {
                if (Target.classList.contains("edit")) {
                    Language.edit(
                        getData(Target, "data-bs-target"),
                        getData(Target, "title"),
                        getData(Target, "data-url"),
                        getData(Target, "data-data")
                    );
                }

                if (Target.name === "default") {
                    this.setDefaultLanguage(Target);
                }
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("languagesUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "language" },
            {
                data: "code",
                width: "3%",
                className: "text-center",
            },
            {
                data: "default",
                width: "7%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "translate",
                width: "7%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtLanguages = new Dt(configDt);
        DtLanguages.dataTable(DtElement);
        // End DataTable
    }

    /**
     * Establecer el idioma por defecto para el usuario
     *
     * @param {HTMLInputElement} item Elemento de tipo input
     */
    setDefaultLanguage(item) {
        bodyRequest.set("lang_id", item.value);

        fetch(getData("profileLanguageUrl"), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                const Translations = await readTranslations(item.value);

                if (data.success) {
                    alertHTML(Translations[data.success], "success");
                }

                if (data.error) {
                    alertHTML(data.error, "error");
                }

                reloadSection();
            })
            .catch((error) => console.log(error));
    }
}

window.customElements.define("language-module", LanguageModule, {
    extends: "section",
});
