import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectProject extends HTMLSelectElement {
    constructor() {
        super();
    }

    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("projectsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (params) {
                    // Simular scroll infinito
                    let page = params.page || 1;
                    return {
                        _token: CSRF,
                        search: params.term,
                        page: page 
                    };
                },
                processResults: function (response, params) {
                    // Simular scroll infinito
                    params.page = params.page || 1;
                    let more = (params.page * response.limit) < response.total_count; 
                    return {
                        results: response.items,
                        pagination: {
                            more: more
                        }
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);
        if(getData(this,"data-default")){
            let def = getJson(b64Uri(getData(this,"data-default"),"decode"));
            setSelect2Ajax(this,def.id,def.name);
        }
    }
}

window.customElements.define("select-project", SelectProject, {
    extends: "select",
});
