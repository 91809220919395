import { alertHTML, getElement } from "../helpers";

class LayoutModule extends HTMLBodyElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Alert = getElement("#alert");

        if (Alert) {
            let alerts = Alert.value;

            if (alerts) {
                alerts = JSON.parse(atob(alerts));
            }

            for (const key in alerts) {
                alertHTML(alerts[key], key);
            }
        } else {
            console.warn("No existe el elemento 'alert'");
        }
    }
}

window.customElements.define("layout-module", LayoutModule, {
    extends: "body",
});
