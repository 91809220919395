import dom from "jsx-render";
import { setDefaultAttributes } from "../helpers";

/**
 * Componente para mostrar el input de tipo password necesario Bootstrap5 y FontAwesome6,
 * otorgando la funcionalidad de mostrar y ocultar la constraseña
 *
 * @param {JSON} custom-attributes objeto convertido a string
 * @return {HTMLDivElement} estructura HTML de Bootstrap 5 para input group
 */
export class InputPassword extends HTMLDivElement {
    customAttributes = {};
    defaultCustomAttributes = {
        input: {},
        button: {},
    };
    inputDefaultAttributes = {
        type: "password",
        class: "form-control",
        name: "password",
        // tabindex: "2",
    };
    buttonDefaultAttributes = {
        // tabindex: "3",
        "aria-label": "Mostrar Contraseña",
        class: "btn btn-outline-secondary",
        type: "button",
    };
    customValue = "";

    constructor() {
        super();
    }

    connectedCallback() {
        // Asignar las propiedades por defecto
        setDefaultAttributes(
            this.defaultCustomAttributes,
            this.customAttributes
        );

        setDefaultAttributes(
            this.inputDefaultAttributes,
            this.customAttributes.input
        );

        setDefaultAttributes(
            this.buttonDefaultAttributes,
            this.customAttributes.button
        );

        const InputPass = (
            <input {...this.customAttributes.input} value={this.customValue} />
        );

        const Button = (
            <button {...this.customAttributes.button}>
                <i class="fas fa-eye-slash"></i>
            </button>
        );

        Button.style.width = "48px";
        this.setAttribute("class", "input-group mt-2 mb-1");
        this.appendChild(InputPass);
        this.appendChild(Button);

        this.addClickEvent();
    }

    attributeChangedCallback(attribute, oldValue, newValue) {
        if (oldValue !== newValue) {
            if (attribute === "custom-attributes") {
                try {
                    newValue = JSON.parse(newValue);
                    setDefaultAttributes(newValue, this.customAttributes);
                } catch (error) {
                    console.log(
                        `El attributo ${attribute} no tiene formato JSON`
                    );
                }
            } else if (attribute === "custom-value") {
                this.customValue = newValue;
            }
        }
    }

    static get observedAttributes() {
        return ["custom-attributes", "custom-value"];
    }

    /**
     * Asigna al evento click la funcionalidad para mostrar y ocultar la contraseña
     * así como el cambio de icono de FontAwesome
     *
     * @return {Void}
     */
    addClickEvent() {
        this.querySelector("button").addEventListener("click", (e) => {
            const Target = e.target.closest("button"); // se puede omitir el parametro "e" y usar this.
            const IptPassword = this.firstElementChild;
            const Icon = Target.firstElementChild;

            if (IptPassword.type === "password") {
                IptPassword.type = "text";
                Icon.classList.remove("fa-eye-slash");
                Icon.classList.add("fa-eye");
            } else {
                IptPassword.type = "password";
                Icon.classList.remove("fa-eye");
                Icon.classList.add("fa-eye-slash");
            }
        });
    }
}

window.customElements.define("input-password", InputPassword, {
    extends: "div",
});
