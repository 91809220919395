import { getElement, resetForm } from "../../../../../helpers";

export class WithholdingModal extends HTMLDivElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        const Form = getElement("[data-form=form]", this);

        Form.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.name == "save") {
                if (Form.reportValidity()) {
                    Form.submit();
                }
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }
}

window.customElements.define("withholding-modal", WithholdingModal, {
    extends: "div",
});
