import inputmask from "inputmask";
import {
    alertHTML,
    b64Uri,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class BankModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity() && this.accountValidate()) {
                Form.submit();
            }
        });

        const AccountMask = new inputmask("AA##-####-####-####-####-####");
        AccountMask.mask(Form.elements.account);
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.pivot.name;
        Form.elements.description.value = data.pivot.description;
        Form.elements.account.value = data.account;
        setSelect2Ajax(
            Form.elements.financial_id,
            data.financial.id,
            data.financial.name
        );
    }

    accountValidate() {
        const Form = getElement("[data-form=form]", this);

        if (Form.elements.account.value.includes("_")) {
            alertHTML("La cuenta está incompleta", "error");

            return false;
        }

        return true;
    }
}

window.customElements.define("bank-modal", BankModal, {
    extends: "div",
});
