import { Management } from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class OldYear extends HTMLSpanElement {
    connectedCallback() {
        this.load();
        this.addEvents();
    }

    load() {
        if (Management) {
            let startYear = parseInt(localStorage.getItem(Management.slug));

            let years = Management.years.map((obj) => obj.year);
            let maxYear = Math.max(...years);

            if (startYear == maxYear) {
                this.classList.add("d-none");
            } else {
                this.classList.remove("d-none");
            }
        }
    }

    addEvents() {
        document.body.addEventListener("aj:startYear", (e) => {
            this.load();
        });
    }
}

window.customElements.define("alert-oldyear", OldYear, {
    extends: "span",
});
