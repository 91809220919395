import {
    b64Uri,
    cleanNumberInput,
    formatCurrency,
    getData,
    getElement,
    getJson,
    isNumber,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";
import moment from "moment/dist/moment";

export class RiskModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);

        Form.elements.value.value = formatCurrency(data.pivot.value, "decimal");

        setSelect2Ajax(Form.elements.financial_id, data.id, data.name);

        let date = moment(data.pivot.expirydate);

        if (date.isValid()) {
            Form.elements.expirydate.value = date.format(
                getData(Form.elements.expirydate, "data-format")
            );
        }
    }
}

window.customElements.define("risk-modal", RiskModal, {
    extends: "div",
});
