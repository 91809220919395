
class VatSumaryModule extends HTMLElement {
    connectedCallback() {
        // this.load();
    }

    load() {
        // console.log(this);
    }
}

window.customElements.define("regularization-module", VatSumaryModule, {
    extends: "section",
});
