import dom, { Fragment } from "jsx-render";
import { getJson } from "../helpers";

/**
 * Componente para mostrar la maquetación de un input con previsualización de imagen
 * necesario Bootstrap5 y FontAwesome6.
 *
 * @param {JSON} custom-attributes objeto convertido a string
 * @return {HTMLDivElement} estructura HTML de Bootstrap 5 para input group
 */
export class InputImage extends HTMLDivElement {
    #customAttributes = {
        input: {
            type: "file",
            name: "logo",
            class: "d-none",
            accept: "image/*",
        },
        img: {
            src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
            alt: "...",
            class: "border border-2 rounded-circle pixel",
            width: "150",
            height: "150",
        },
    };

    constructor() {
        super();
    }

    connectedCallback() {
        let attributes = this.getAttribute("custom-attributes");

        if (attributes) {
            attributes = getJson(attributes);
            this.#customAttributes.input = {
                ...this.#customAttributes.input,
                ...attributes.input,
            };

            this.#customAttributes.img = {
                ...this.#customAttributes.img,
                ...attributes.img,
            };
        }

        this.appendChild(
            <>
                <div>
                    <img {...this.#customAttributes.img} />
                </div>
                <div class="w-100">
                    <label class="mt-n3 w-auto" role="button">
                        <i class="fas fa-camera fa-2x"></i>
                        <input {...this.#customAttributes.input} />
                    </label>
                </div>
            </>
        );

        this.firstElementChild.style.width = "180px";
        this.firstElementChild.style.height = "150px";

        this.addInputEvent();
    }

    /**
     * Asigna el evento input para realizar el preview de la imagen
     *
     * @return {Void}
     */
    addInputEvent() {
        this.addEventListener("input", (e) => {
            // if ("input" != e.target.nodeName.toLowerCase()) return; // usar esto para detener la propagación en el evento click

            const Target = e.target;

            const Img = Target.files[0];

            // if (Img.type != "image/jpeg" && Img.type != "image/png") {
            //     showMessageAdmin("La imagen puede ser de tipo jpg o png", "error");
            //     Target.value = "";
            // } else {
            let dataImage = new FileReader();
            dataImage.readAsDataURL(Img);

            dataImage.addEventListener("loadend", () => {
                let imageSrc = dataImage.result;

                this.firstElementChild.firstElementChild.setAttribute(
                    "src",
                    imageSrc
                );
            });
            // }
        });
    }
}

window.customElements.define("input-image", InputImage, {
    extends: "div",
});
