import { CSRF, alertHTML, b64Uri, createForm, elementClass, getData, getElement, resetSelect2Ajax } from "../../../helpers";

class FormTransactionModule extends HTMLElement {
    connectedCallback() {

        console.log(this);
        const tbody = getElement("[name=tbodytransaction]", this);
        let row = tbody.lastElementChild.cloneNode(true);
        //Acciones
        const BtnNewLine = getElement("[name=new_line]", this);
        BtnNewLine?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);
            getElement("[name=accountingplan]",row2).removeAttribute("data-selected");
            tbody.appendChild(row2);       
            getElement("[name=debit]",row2).value="";  
            getElement("[name=credit]",row2).value="";  
            getElement("[name=label]",row2).value="";  
        });
        this.addEventListener("aj:select2",(e) => {
            if(e.detail.data){
                elementClass(e.target.nextElementSibling,'border-danger','remove');
            }
            
        });

        const BtnSave = getElement("[name=save]",this);
        let transactions = [];
        BtnSave?.addEventListener("click", () => {
            const table = getElement("[name=tbodytransaction]",this);
            transactions = [];
            if(this.validate()){
                table.childNodes.forEach(row => {
                    if(row instanceof HTMLTableRowElement){
                        let accountingplan = getElement("[name=accountingplan]",row);
                        const debit = getElement("[name=debit]",row);
                        const credit = getElement("[name=credit]",row);
                        let transaction = {
                            date: getElement("[name=date]",this).value,
                            project: getElement("[name=project]",this).value,
                            accountingplan_code: accountingplan.value,
                            accountingplan_name: accountingplan.options[accountingplan.selectedIndex].text,
                            description: getElement("[name=label]",row).value,
                            debit: debit.value,
                            credit: credit.value,
                            ref: getElement("[name=ref]",this).value
                        };
                        transactions.push(transaction);
                    }
                });
                
            }else{
                alertHTML(getData(table,'data-error'));
            }
            if(transactions.length){
                createForm(getData(table,'data-url'),{data: b64Uri(transactions)});  
            }
        });

        this.addEventListener("change",(e) => {
            const target = e.target;
            const debit = target.closest('[name=debit]');
            const credit = target.closest('[name=credit]');
            
            if(debit){
                const credit = debit.parentElement.nextElementSibling.firstElementChild;
                elementClass(credit,'is-invalid','remove');
                elementClass(debit,'is-invalid','remove');
                if(debit.value.length > 0){
                    credit.value = "";
                    credit.disabled = true;
                }else
                    credit.disabled = false;
            }
            if(credit){
                const debit = credit.parentElement.previousElementSibling.firstElementChild;
                elementClass(credit,'is-invalid','remove');
                elementClass(debit,'is-invalid','remove');
                if(credit.value.length > 0){
                    debit.value = "";
                    debit.disabled = true;
                }else
                    debit.disabled = false;
            }
        });
    }

    validate(){
        const table = getElement("[name=tbodytransaction]",this);
        var success = false;
        if(table.children.length > 0){
            success = true;
            table.childNodes.forEach(row => {
                if(row instanceof HTMLTableRowElement){
                    let accountingplan = getElement("[name=accountingplan]",row);
                    const debit = getElement("[name=debit]",row);
                    const credit = getElement("[name=credit]",row);
                   
                    if(accountingplan.selectedIndex < 0){
                        elementClass(accountingplan.nextElementSibling,'border-danger');
                        success = false;
                    }
                    if(!debit.value && !credit.value){
                        elementClass(credit);
                        elementClass(debit);
                        success = false;
                    }
                }
            });
            
        }
        return success;
    }
}

window.customElements.define("formtransaction-module", FormTransactionModule, {
    extends: "section",
});
