import { confirmAction, getData, getElement } from "../helpers";

class SettleVat extends HTMLButtonElement {
    connectedCallback() {
        this.test();
        this.addEvents();
    }

    test() {
        // console.log(this);
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const Form = getElement("[name=filter]");
            let dataForm = new FormData(Form);
            let filter = Array.from(dataForm).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
            
            confirmAction(
                getData(this, "title"),
                "",
                getData(this, "data-url"),
                filter,
                getData(this, "data-confirm"),
                getData(this, "data-cancel")
            );
        });
    }
}

window.customElements.define("settle-vat", SettleVat, {
    extends: "button",
});
