import { createForm, getData } from "../helpers";

class EquivalenceSurcharge extends HTMLDivElement {
    connectedCallback() {
        this.firstElementChild.addEventListener("click", (e) => {
            const Target = e.target;
            createForm(getData(Target, "data-url"), {
                equivalence_surcharge: Target.checked ? 1 : 0,
            });
        });
    }
}

window.customElements.define("equivalence-surcharge", EquivalenceSurcharge, {
    extends: "div",
});
