import Swal from "sweetalert2/dist/sweetalert2";
import { getData, getElement } from "../helpers";

class DeleteRow extends HTMLButtonElement {
    connectedCallback() {
        this.addEventListener("click", () => {
            // crear evento Personalizado
            const CE = new CustomEvent("cl:deleteRow", {
                detail: true,
                bubbles: true,
                cancelable: false,
            });

            Swal.fire({
                title: getData(this, "title"),
                text: getData(this, "data-text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: getData(this, "data-delete"),
                cancelButtonText: getData(this, "data-cancel"),
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.value) {
                    const table =
                        this.parentElement.parentElement.parentElement;
                    const row = this.parentElement.parentElement;
                    const Tbody = row.parentElement;

                    row.remove();

                    if (!table.children.length) {
                        const BtnNewLine = getElement(
                            "[name=new_line]",
                            table.parentElement
                        );
                        BtnNewLine?.click();
                    }

                    Tbody.dispatchEvent(CE); // pasar al padre existente en el DOM para que bubbles funcione y después de haber removido la fila
                }
            });
        });
    }
}

window.customElements.define("delete-row", DeleteRow, {
    extends: "button",
});
