import {
    bodyRequest,
    getData,
    getElement,
    formatTel,
    requestOptions,
    showOrHide,
    b64Uri,
} from "../../../../../helpers";

class ModalCountries extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.info(this);
        const Digits = getElement("input[name=digits]", this);
        const BtnSelectCountry = getElement(
            "button[name=btnSelectCountry]",
            this
        );
        let flag = null;
        // let iptTel = null;
        let country = null;

        // Itendificar el elemento que abre el modal
        this.addEventListener("show.bs.modal", (e) => {
            flag = e.relatedTarget.firstElementChild;
            // iptTel = e.relatedTarget.nextElementSibling;
        });

        // Evento personalizado desde resources/js/plugins/select2.js
        this.addEventListener("aj:select2", (e) => {
            // Comprobar que sea el componente elgido
            if (e.target.name === "country") {
                country = e.detail.data;
                const CntDigits =
                    e.target.nextElementSibling.nextElementSibling;

                Digits.value = "";
                showOrHide(CntDigits, !country.digits);
            }
        });

        Digits.addEventListener("input", () => {
            // Actualizar en la bd los digitos
            if (parseInt(Digits.value)) {
                Digits.classList.remove("is-invalid");
                bodyRequest.set("digits", Digits.value);
                fetch(getData("countryUrlUpdate") + country.id, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.response) {
                            country = data;
                        }
                    })
                    .catch((error) => console.log(error));
            } else {
                Digits.classList.add("is-invalid");
            }
        });

        BtnSelectCountry.addEventListener("click", () => {
            // Cambiar la bandera
            if (country && country.digits > 0) {
                formatTel(country, flag);
                // Asignar el país como atributo
                flag.parentElement.nextElementSibling.setAttribute(
                    "data-country",
                    b64Uri(country)
                );
            }
        });
    }
}

window.customElements.define("modal-countries", ModalCountries, {
    extends: "div",
});
