import tippy from "tippy.js";
import { CSRF, getData, getElement } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { ContractModal as Contract } from "./modals/store";

class ContractModule extends HTMLElement {
    connectedCallback() {
        // console.log(this);
        this.load();
        this.addEvents();
        this.tester();
    }

    load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("contractsUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name" },
            { data: "cif" },
            { data: "code" },
            { data: "cups" },
            { data: "date", className: "text-center" },
            { data: "telemetered", className: "text-center" },
            { data: "paymentmethod" },
            { data: "bank" },
            { data: "address", className: "text-center" },
            { data: "status", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            tippy(".tippy-tooltips");
        };

        const DtContracts = new Dt(configDt);
        DtContracts.dataTable(DtElement);
        // End DataTable
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnNew = Target.closest("[name=new]"); // Identificar el botón para crear
            const BtnEdit = Target.closest("[name=btnEdit]"); // Identificar el botón para crear

            if (BtnNew) {
                Contract.create(
                    getData(BtnNew, "data-bs-target"),
                    getData(BtnNew, "title"),
                    getData(BtnNew, "data-url")
                );
            }

            if (BtnEdit) {
                Contract.edit(
                    getData(BtnEdit, "data-bs-target"),
                    getData(BtnEdit, "title"),
                    getData(BtnEdit, "data-url"),
                    getData(BtnEdit, "data-data")
                );
            }
        });
    }

    tester() {
        // método para hacer pruebas NO BORRAR
    }
}

window.customElements.define("contract-module", ContractModule, {
    extends: "section",
});
