import {
    b64Uri,
    bodyRequest,
    defaultSelect2,
    getData,
    getElement,
    getJson,
    requestOptions,
    resetForm,
    setSelect2Ajax,
} from "../../../../../../helpers";
import { addresses } from "../../../addresses/modals/modalAddress";
import Swal from "sweetalert2/dist/sweetalert2";
export class JournalModal extends HTMLDivElement {
    connectedCallback() {
        console.log(this);
        const FormJournal = getElement("[data-form=form]", this);

        FormJournal.elements.save.addEventListener("click", () => {
            if (FormJournal.reportValidity()) {
                FormJournal.submit();
            }
        });
    }

    static create(modal, url, title) {
        const FormJournal = getElement("[data-form=form]", getElement(modal));
        resetForm(FormJournal, title, url);
    }

    static edit(modal, url, title, data) {
        data = JSON.parse(atob(data));
        const form = getElement("[data-form=form]", getElement(modal));
        resetForm(form, title, url);
        form.elements.name.value = data.name;
        form.elements.code.value = data.code;
        
    }
}

window.customElements.define("journal-modal", JournalModal, {
    extends: "div",
});
