import { getData } from "../helpers";
import { ShareSupplier } from "../views/admin/purchases/suppliers/modals/share";

class ShareSuppliers extends HTMLButtonElement {
    connectedCallback() {
        this.test();
        this.load();
        this.events();
    }

    test() {
        // No borrar este método, pero sí su contenido
    }

    load() {
        //
    }

    events() {
        this.addEventListener("click", () => {
            let view = this.name == "shareView";
            ShareSupplier.shareCompanies(
                getData(this, "data-bs-target"),
                getData(this, "title"),
                getData(this, "data-url"),
                getData(this, "data-data"),
                view
            );
        });
    }
}

window.customElements.define("share-suppliers", ShareSuppliers, {
    extends: "button",
});
