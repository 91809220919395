import { getData, getElement, tabs } from "../../../../../../helpers";
import { Dt } from "../../../../../../plugins/datatable";
import { BankModal as Bank } from "../../../../../settings/settings/banks/modals/store";
import { TaxSupplier } from "../../modals/tax";
import { TagSupplier } from "../../modals/tag";
import { AttachProject } from "../../../../../settings/settings/projects/modals/attach";

class TabDataSupplier extends HTMLDivElement {
    connectedCallback() {
        this.addEvents();
        this.banks();
        this.taxes();
        this.addresses();
        this.tags();
        this.projects();
    }

    addEvents() {
        this.addEventListener("click", (e) => {
            const BtnAddBank = e.target.closest("[name=btnAddBank]");
            const BtnEditBank = e.target.closest("[name=btnEditBank]");
            const BtnAddTax = e.target.closest("[name=btnAddTax]");
            const BtnEditTax = e.target.closest("[name=btnEditTax]");
            const BtnAddTag = e.target.closest("[name=btnAddTag]");
            const BtnEditTag = e.target.closest("[name=btnEditTag]");
            const Tab = e.target.closest("[data-bs-toggle=pill]");
            const BtnAddProject = e.target.closest("[name=btnAddProject]");
            const BtnEditProject = e.target.closest("[name=btnEditProject]");

            // Recordar Tab
            if (Tab) {
                tabs(Tab);
            }

            // Agregar Banco
            if (BtnAddBank) {
                Bank.create(
                    getData(BtnAddBank, "data-bs-target"),
                    getData(BtnAddBank, "title"),
                    getData(BtnAddBank, "data-url")
                );
            }

            // Editar Banco
            if (BtnEditBank) {
                Bank.edit(
                    getData(BtnEditBank, "data-bs-target"),
                    getData(BtnEditBank, "title"),
                    getData(BtnEditBank, "data-url"),
                    getData(BtnEditBank, "data-data")
                );
            }

            // Agregar Impuesto
            if (BtnAddTax) {
                TaxSupplier.create(
                    getData(BtnAddTax, "data-bs-target"),
                    getData(BtnAddTax, "title"),
                    getData(BtnAddTax, "data-url")
                );
            }

            // Editar Impuesto
            if (BtnEditTax) {
                TaxSupplier.edit(
                    getData(BtnEditTax, "data-bs-target"),
                    getData(BtnEditTax, "title"),
                    getData(BtnEditTax, "data-url"),
                    getData(BtnEditTax, "data-data")
                );
            }

            // Agregar Etiqueta
            if (BtnAddTag) {
                TagSupplier.create(
                    getData(BtnAddTag, "data-bs-target"),
                    getData(BtnAddTag, "title"),
                    getData(BtnAddTag, "data-url")
                );
            }

            // Editar Etiqueta
            if (BtnEditTag) {
                TagSupplier.edit(
                    getData(BtnEditTag, "data-bs-target"),
                    getData(BtnEditTag, "title"),
                    getData(BtnEditTag, "data-url"),
                    getData(BtnEditTag, "data-data")
                );
            }

            // Agregar Etiqueta
            if (BtnAddProject) {
                AttachProject.create(
                    getData(BtnAddProject, "data-bs-target"),
                    getData(BtnAddProject, "title"),
                    getData(BtnAddProject, "data-url")
                );
            }

            // Editar Etiqueta
            if (BtnEditProject) {
                AttachProject.edit(
                    getData(BtnEditProject, "data-bs-target"),
                    getData(BtnEditProject, "title"),
                    getData(BtnEditProject, "data-url"),
                    getData(BtnEditProject, "data-data")
                );
            }
        });
    }

    banks() {
        const DtBanks = getElement("[data-table=dtBanks]", this);

        // Start DataTable
        let configDt = {
            pageLength: 4,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 6],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtB = new Dt(configDt);
        DtB.dataTable(DtBanks);
        // End DataTable
    }

    taxes() {
        const DtTaxes = getElement("[data-table=dtTaxes]", this);

        // Start DataTable
        let configDt = {
            pageLength: 4,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 3, 4, 5],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtT = new Dt(configDt);
        DtT.dataTable(DtTaxes);
        // End DataTable
    }

    addresses() {
        const DtAddresses = getElement("[data-table=dtAddresses]", this);

        // Start DataTable
        let configDt = {
            pageLength: 3,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 2, 3],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtA = new Dt(configDt);
        DtA.dataTable(DtAddresses);
        // End DataTable
    }

    tags() {
        const DtTags = getElement("[data-table=dtTags]", this);

        // Start DataTable
        let configDt = {
            pageLength: 4,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 2],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtTg = new Dt(configDt);
        DtTg.dataTable(DtTags);
        // End DataTable
    }

    projects() {
        const DtProjects = getElement("[data-table=dtProjects]", this);

        // Start DataTable
        let configDt = {
            pageLength: 4,
            lengthChange: false,
            ordering: false,
            columnDefs: [
                {
                    targets: [0, 2],
                    width: "5%",
                    className: "text-center",
                },
            ],
        };

        const DtTg = new Dt(configDt);
        DtTg.dataTable(DtProjects);
        // End DataTable
    }
}

window.customElements.define("tabdata-supplier", TabDataSupplier, {
    extends: "div",
});
