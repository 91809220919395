import { Modal, getInstance } from "bootstrap";
import {
    alertHTML,
    confirmDelete,
    formatCurrency,
    getData,
    getElement,
} from "../helpers";
import { ReceiptModal } from "../views/admin/sales/receipts/modals/store";

class ReceiptButton extends HTMLButtonElement {
    connectedCallback() {
        this.load();
    }

    load() {
        const modal = ReceiptModal.create(
            getData(this, "data-bs-target"),
            getData(this, "data-url"),
            getData(this, "data-receipt"),
            getData(this, "data-management")
        );

        this.addEventListener("click", () => {
            let data = [];
            let error = false;
            let mindate = null;
            if (getData(this, "data-data")) {
                let elem = JSON.parse(atob(getData(this, "data-data")));
                data.push(elem);
                mindate = elem.date_receipt;
            } else {
                let nodes = document.getElementsByName("check");
                let supplierid = "";
                for (const node of nodes) {
                    if (node.checked) {
                        let elem = JSON.parse(atob(getData(node, "data-data")));
                        if (supplierid != "" && supplierid != elem.supplier_id)
                            error = true;

                        supplierid = elem.supplier_id;
                        data.push(elem);

                        if (!mindate) mindate = elem.date_receipt;
                        else {
                            let d1 = new Date(mindate);
                            let d2 = new Date(elem.date_receipt);
                            if (d2.getTime() < d1.getTime())
                                mindate = elem.date_receipt;
                        }
                    }
                }
            }
            if (error) {
                alertHTML("No es posible unir cobros de distintos clientes");
            } else {
                getElement("[name=receiptdate]", getElement(modal)).value =
                    mindate;
                getElement("[name=total_receipt]", getElement(modal)).value =
                    formatCurrency(0);
                const table = getElement(
                    "[name=table-body]",
                    getElement(modal)
                );
                let html = "";
                data.forEach(function (inv) {
                    console.log(inv);
                    let amount = 0;
                    inv.saleinvoicelines.forEach(function (elem) {
                        amount +=
                            parseFloat(elem.quantity) *
                            (parseFloat(elem.unit_price) +
                                parseFloat(elem.unit_tax));
                    });
                    html += "<tr>";
                    html += "<td>" + inv.ref + "</td>";
                    html += "<td>" + inv.date_receipt + "</td>";
                    html +=
                        "<td>" + formatCurrency(amount - inv.receipt) + "</td>";
                    html +=
                        "<td><div class='input-group input-group-sm mt-1'><input type='text' class='form-control form-control-sm' data-ref='" +
                        inv.ref +
                        "' name='receipt_" +
                        inv.id +
                        "'/><i class='input-group-text fa-solid fa-money-bill-transfer pt-2' name='all'></i></div></td>";
                    html += "</tr>";
                });
                table.innerHTML = html;
            }
        });
    }
}

window.customElements.define("receipt-button", ReceiptButton, {
    extends: "button",
});
