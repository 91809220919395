import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import {
    Management,
    b64Uri,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class PaymentmethodModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        this.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.name == "need_bank") {
                if (parseInt(Target.value)) {
                    Form.elements.purchasesaccount_id.removeAttribute(
                        "required"
                    );
                    Form.elements.salesaccount_id.removeAttribute("required");
                } else {
                    Form.elements.purchasesaccount_id.setAttribute(
                        "required",
                        ""
                    );
                    Form.elements.salesaccount_id.setAttribute("required", "");
                }
            }
        });

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        // dataAccounting.accountingplans = [];
        // dataAccounting.concat = [];
    }

    static edit(modal, title, url, data, type) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.name;
        Form.elements.days.value = data.days;
        Form.elements.description.value = data.description;
        Array.from(Form.elements.need_bank).find(
            (item) => item.value == data.need_bank
        ).checked = true;
        setSelect2Ajax(Form.elements.type, data.type, type);

        let managament = data.managements.find(
            (item) => item.company_id == Management.company_id
        );

        if (managament) {
            // dataAccounting.concat = [];

            if (managament.pivot.salesaccount) {
                setSelect2Ajax(
                    Form.elements.salesaccount_id,
                    managament.pivot.salesaccount.id,
                    `${managament.pivot.salesaccount.number} - ${managament.pivot.salesaccount.name}`
                );

                // dataAccounting.concat["salesaccount_id"] =
                //     managament.pivot.salesaccount.id;
            }

            if (managament.pivot.purchasesaccount) {
                setSelect2Ajax(
                    Form.elements.purchasesaccount_id,
                    managament.pivot.purchasesaccount.id,
                    `${managament.pivot.purchasesaccount.number} - ${managament.pivot.purchasesaccount.name}`
                );

                // dataAccounting.concat["purchasesaccount_id"] =
                //     managament.pivot.purchasesaccount.id;
            }

            // dataAccounting.accountingplans = Object.values(
            //     dataAccounting.concat
            // );
        }
    }
}

window.customElements.define("paymentmethod-modal", PaymentmethodModal, {
    extends: "div",
});
