import dom, { Fragment } from "jsx-render";
import { dataBank } from "../../../../../components/selectBank";
import {
    b64Uri,
    getData,
    getElement,
    getJson,
    resetForm,
    resetSelect2Ajax,
    setSelect2Ajax,
} from "../../../../../helpers";
import { addresses } from "../../../../settings/settings/addresses/modals/modalAddress";
import moment from "moment";
import { dataShippingmethod } from "../../../../../components/selectShippingmethod";

export class ContractModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (this.validateAddresses() & Form.reportValidity()) {
                Form.submit();
            }
        });

        this.addEventListener("aj:select2", (e) => {
            if (e.target.name === "customer_company_id") {
                const Data = e.detail.data;
                dataBank.companies = [Data.company_id];
                resetSelect2Ajax(getElement('[is="select-bank"]', this));
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.code.value = data.code;
        Form.elements.cups.value = data.cups;
        dataBank.customers = [data.customer_company_id];
        Form.elements.telemetered.checked = data.telemetered;

        let start_date = moment(data.start_date);
        let end_date = moment(data.end_date);

        if (start_date.isValid()) {
            Form.elements.start_date.value = start_date.format(
                getData(Form.elements.start_date, "data-format")
            );
        }

        Form.elements.start_date.dispatchEvent(
            new Event("change", { bubbles: true })
        );

        if (end_date.isValid()) {
            Form.elements.end_date.value = end_date.format(
                getData(Form.elements.end_date, "data-format")
            );
        }

        Form.elements.end_date.dispatchEvent(
            new Event("change", { bubbles: true })
        );

        setSelect2Ajax(
            Form.elements.customer_company_id,
            data.customer_company_id,
            data.name
        );
        setSelect2Ajax(
            Form.elements.paymentmethod_id,
            data.paymentmethod.id,
            data.paymentmethod.name
        );
        setSelect2Ajax(Form.elements.bank_id, data.bank.id, data.bank.account);

        data.shippingmethods.forEach((item) => {
            setSelect2Ajax(
                getElement('[is="select-shippingmethod"]', Form),
                item.id,
                item.name
            );
        });

        dataShippingmethod.shippingmethods = data.shippingmethods.map(
            (item) => item.id
        );

        data.addresses?.forEach((address, index) => {
            address.id = index + 1;
            address.buttons = null;

            address.contacts.forEach((contact, key) => {
                contact.id = key + 1;
                contact.buttons = null;
            });
        });

        Form.elements.addresses.value = b64Uri(data.addresses);
        addresses.addresses = data.addresses;
    }

    validateAddresses() {
        const Form = getElement("[data-form=form]", this);
        let errors = [];

        if (Form.elements.addresses.value) {
            let data = true;
            let addresses = getJson(
                b64Uri(Form.elements.addresses.value, "decode")
            );

            if (addresses.length) {
                data = addresses.filter((item) => !item.contacts.length);

                if (data.length) {
                    errors.push(getData(this, "data-contactEmpty"));
                } else {
                    data = addresses.filter((item) =>
                        item.contacts.some(
                            (element) =>
                                !element.email.email || !element.tel.tel
                        )
                    );

                    if (data.length) {
                        errors.push(getData(this, "data-contactEmailTelEmpty"));
                    }
                }
            } else {
                errors.push(getData(this, "data-addressEmpty"));
            }
        } else {
            errors.push(getData(this, "data-addressEmpty"));
        }

        if (errors.length) {
            Form.elements.addresses.setCustomValidity(errors.toString());
        } else {
            Form.elements.addresses.setCustomValidity("");
        }

        return !errors.length;
    }
}

window.customElements.define("contract-modal", ContractModal, {
    extends: "div",
});
