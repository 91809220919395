import { getData } from "../helpers";
import { CompanyModal as Company } from "./settings/settings/companies/modals/store";

class CompanieModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        this.addEventListener("click", (e) => {
            const BtnEditCompany = e.target.closest(
                "[data-target=btnEditCompany]"
            );

            const BtnNewCompany = e.target.closest(
                "[data-target=btnNewCompany]"
            );

            const BtnDeleteCompany = e.target.closest('[is="delete-button"]');

            const TargetDiv = e.target.closest("div[data-href]");

            if (BtnNewCompany) {
                Company.create(
                    getData(BtnNewCompany, "data-bs-target"),
                    getData(BtnNewCompany, "title"),
                    getData(BtnNewCompany, "data-url")
                );
            }

            if (BtnEditCompany) {
                Company.edit(
                    getData(BtnEditCompany, "data-bs-target"),
                    getData(BtnEditCompany, "title"),
                    getData(BtnEditCompany, "data-url"),
                    getData(BtnEditCompany, "data-data")
                );
            }

            if (
                !BtnNewCompany &&
                !BtnEditCompany &&
                !BtnDeleteCompany &&
                TargetDiv
            ) {
                window.location.href = getData(TargetDiv, "data-href") ?? "#";
            }
        });
    }
}

window.customElements.define("companies-module", CompanieModule, {
    extends: "section",
});
