import tippy from "tippy.js";
import {
    CSRF,
    b64Uri,
    getData,
    getElement,
    getJson,
} from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { PaymentModal } from "./modals/store";

class PaymentModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        /*const url = window.location.href;
        const urlParams = new URLSearchParams(new URL(url).search);
        if(urlParams.get('p')){
            const dataEdit = getJson(b64Uri(urlParams.get('p'),'decode'));
            PaymentModal.create()
        }*/

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        let filtro = {
            paystart: "",
            payend: "",
            payed: "",
            accounted: "",
        };

        configDt.ajax = {
            url: getData("paymentsUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filtro;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "id", width: "5%", className: "text-center" },
            {
                data: "date",
                className: "text-center",
                width: "7%",
            },
            { data: "paymentmethod" },
            { data: "bank" },
            { data: "amount" },
            { data: "paid", width: "6%" },
            { data: "accounted", width: "6%" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            tippy(".tippy-tooltips");
        };

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.paystart = getElement("[name=paystart]", this).value;
            filtro.payend = getElement("[name=payend]", this).value;
            filtro.payed = getElement("[name=payed]", this).value;
            filtro.accounted = getElement("[name=accounted]", this).value;
            T.ajax.reload();
        });
        BtnReset?.addEventListener("click", () => {
            getElement("[name=paystart]", this).value = "";
            filtro.paystart = "";
            getElement("[name=payend]", this).value = "";
            filtro.payend = "";
            getElement("[name=payed]", this).value = "";
            filtro.payed = "";
            getElement("[name=accounted]", this).value = "";
            filtro.accounted = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("payments-module", PaymentModule, {
    extends: "section",
});
