import {
    alertHTML,
    b64Uri,
    cleanFloat,
    formatCurrency,
    getData,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
    Management,
    isNumber,
    DecimalLength,
} from "../../../../../helpers";

export class SaleReturnsModal extends HTMLDivElement {
    constructor(element = null) {
        super(); // Llama al constructor de HTMLDivElement

        if (element) {
            // Heredar de la clase al elemento del DOM
            Object.setPrototypeOf(element, SaleReturnsModal.prototype);
            return element;
        }
    }

    connectedCallback() {
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", (e) => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, url, title, data) {
        modal = getElement(modal);
        const Form = getElement("[data-form=form]", modal);
        resetForm(Form, title, url);
        data = getJson(b64Uri(data, "decode"));

        if (data) {
            setSelect2Ajax(
                Form.elements.account,
                data.id,
                data.number + " - " + data.name
            );
        }
    }
}

window.customElements.define("salereturns-modal", SaleReturnsModal, {
    extends: "div",
});
