import {
    CSRF,
    Management,
    b64Uri,
    getData,
    getElement,
    getJson,
    resetSelect2Ajax,
} from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { PaymentModal } from "../payments/modals/store";

class PurchaseInvoiceModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnAdd = getElement("#btnAdd", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const BtnPay = getElement("#btnPay", this);

        let filtro = {
            invstart: "",
            invend: "",
            paystart: "",
            payend: "",
            supplier: "",
            payed: "",
            accounted: "",
        };

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        configDt.ajax = {
            url: getData("purchaseInvoicesUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filtro;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            {
                data: "check",
                width: "2%",
                className: "text-center",
                orderable: false,
            },
            { data: "ref", width: "5%", className: "text-center" },
            {
                data: "date_register",
                width: "6%",
            },
            {
                data: "date_invoice",
                width: "6%",
            },
            { data: "supplier_invoice" },
            { data: "supplier" },
            { data: "payment_method" },
            { data: "date_payment" },
            { data: "amount", class: "text-end" },
            { data: "balance", class: "text-end" },
            { data: "paid", width: "6%" },
            { data: "accounted", width: "6%" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.invstart = getElement("[name=invstart]", this).value;
            filtro.invend = getElement("[name=invend]", this).value;
            filtro.paystart = getElement("[name=paystart]", this).value;
            filtro.payend = getElement("[name=payend]", this).value;
            filtro.supplier = getElement("[name=supplier]", this).value;
            filtro.payed = getElement("[name=payed]", this).value;
            filtro.accounted = getElement("[name=accounted]", this).value;
            T.ajax.reload();

            BtnPay.classList.add("d-none");
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=invstart]", this).value = "";
            filtro.invstart = "";
            getElement("[name=invend]", this).value = "";
            filtro.invend = "";
            getElement("[name=paystart]", this).value = "";
            filtro.paystart = "";
            getElement("[name=payend]", this).value = "";
            filtro.payend = "";
            getElement("[name=supplier]", this).value = "";
            resetSelect2Ajax(getElement("[name=supplier]", this));
            filtro.supplier = "";
            getElement("[name=payed]", this).value = "";
            filtro.payed = "";
            getElement("[name=accounted]", this).value = "";
            filtro.accounted = "";
            T.ajax.reload();

            BtnPay.classList.add("d-none");
        });

        this.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.name == "check") {
                const IptsCheck = document.querySelectorAll("[name=check]");
                let companySelected = getData(Target, "data-company");

                if (Target.checked) {
                    IptsCheck.forEach((item) => {
                        if (getData(item, "data-company") != companySelected) {
                            item.checked = false;
                            item.disabled = true;
                        }
                    });

                    BtnPay.classList.remove("d-none");
                } else {
                    const IptsChecked = Array.from(IptsCheck).filter(
                        (item) => item.checked
                    );

                    if (IptsChecked.length < 1) {
                        IptsCheck.forEach((item) => {
                            item.checked = false;
                            item.disabled = false;
                        });

                        BtnPay.classList.add("d-none");
                    }
                }
            }

            if (Target.id == "btnPay") {
                const PM = new PaymentModal(getElement("#modalPayment"));
                PM.create(
                    getData(Target, "data-bs-target"),
                    getData(Target, "title"),
                    getData(Target, "data-url")
                );
            }
        });

        document.body.addEventListener("aj:startYear", (e) => {
            BtnPay.classList.add("d-none");
            this.disabledByYear();
        });

        this.disabledByYear();
    }

    disabledByYear() {
        const BtnAdd = getElement("#btnAdd", this);
        let startYear = parseInt(localStorage.getItem(Management.slug));
        let years = Management.years.map((obj) => obj.year);
        // let minYear = Math.min(...years);
        let maxYear = Math.max(...years);

        if (maxYear - startYear > 1) {
            BtnAdd.classList.add("d-none");
        } else {
            // if (maxYear - minYear > 1) {
            // if (maxYear - startYear > 0) {
            BtnAdd.classList.remove("d-none");
            // }
            // }
        }
    }
}

window.customElements.define("purchaseinvoices-module", PurchaseInvoiceModule, {
    extends: "section",
});
