import { DateRangePicker } from "../plugins/dateRangePicker";

export class InputSimpleDate extends HTMLInputElement {
    async connectedCallback() {
        await this.load();
    }

    async load() {
        const DateRP = new DateRangePicker(this);
        const DRP = await DateRP.picker();
    }
}

window.customElements.define("simple-date", InputSimpleDate, {
    extends: "input",
});
