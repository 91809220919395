import { CSRF, getData, getElement } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { FinancialModal } from "./modals/store";

class FinancialModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        const DtElement = getElement("[data-table=dt]", this);
        // const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("financialUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name" },
            { data: "swift" },
            {
                data: "status",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtCompanies = new Dt(configDt);
        DtCompanies.dataTable(DtElement);
        // End DataTable

        this.addEventListener("click", (e) => {
            const BtnNewFinancial = e.target.closest("[name=btnNewFinancial]");
            const BtnEditFinancial = e.target.closest(
                "[name=btnEditFinancial]"
            );

            // Agregar Banco
            if (BtnNewFinancial) {
                FinancialModal.create(
                    getData(BtnNewFinancial, "data-bs-target"),
                    getData(BtnNewFinancial, "title"),
                    getData(BtnNewFinancial, "data-url")
                );
            }

            // Editar Banco
            if (BtnEditFinancial) {
                FinancialModal.edit(
                    getData(BtnEditFinancial, "data-bs-target"),
                    getData(BtnEditFinancial, "title"),
                    getData(BtnEditFinancial, "data-url"),
                    getData(BtnEditFinancial, "data-data")
                );
            }
        });
    }
}

window.customElements.define("financial-module", FinancialModule, {
    extends: "section",
});
