import { CSRF, getData, getElement } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { ProjectModal } from "./modals/store";

class ProjectsModule extends HTMLElement {
    connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("projectsUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name" },
            {
                data: "status",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtData = new Dt(configDt);
        DtData.dataTable(DtElement);
        // End DataTable

        this.addEventListener("click", (e) => {
            const BtnNewProject = e.target.closest("[name=btnNewProject]");
            const BtnEditProject = e.target.closest("[name=btnEditProject]");

            // Agregar Banco
            if (BtnNewProject) {
                ProjectModal.create(
                    getData(BtnNewProject, "data-bs-target"),
                    getData(BtnNewProject, "title"),
                    getData(BtnNewProject, "data-url")
                );
            }

            // Editar Banco
            if (BtnEditProject) {
                ProjectModal.edit(
                    getData(BtnEditProject, "data-bs-target"),
                    getData(BtnEditProject, "title"),
                    getData(BtnEditProject, "data-url"),
                    getData(BtnEditProject, "data-data")
                );
            }
        });
    }
}

window.customElements.define("project-module", ProjectsModule, {
    extends: "section",
});
