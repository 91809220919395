import {
    CSRF,
    bodyRequest,
    getData,
    getElement,
    requestOptions,
    DecimalLength,
    resetSelect2Ajax,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class TransactionsModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let filtro = {
            project: "",
            start: "",
            end: "",
            accountstart: "",
            accountend: "",
            description: "",
        };

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        configDt.ajax = {
            url: getData("transactionsUrlDt"),
            type: "POST",
            data: function (data) {
                data.project = filtro.project;
                data.startDate = filtro.start;
                data.endDate = filtro.end;
                data.accountstart = filtro.accountstart;
                data.accountend = filtro.accountend;
                data.description = filtro.description;
                data.type = "accounting";
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "ref", width: "3%", className: "text-center" },
            { data: "dateformat" },
            { data: "accountingplan_name" },
            { data: "subaccountingplan_name" },
            { data: "label" },
            { data: "project" },
            { data: "debit" },
            { data: "credit" },
            { data: "generated" },
            { data: "buttons", width: "3%", className: "text-center" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.start = getElement("[name=start]", this).value;
            filtro.end = getElement("[name=end]", this).value;
            filtro.project = getElement("[name=project]", this).value;
            filtro.accountstart = getElement("[name=accountstart]", this).value;
            filtro.accountend = getElement("[name=accountend]", this).value;
            filtro.description = getElement("[name=description]", this).value;
            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=description]", this).value = "";
            filtro.description = "";
            getElement("[name=start]", this).value = "";
            filtro.start = "";
            getElement("[name=end]", this).value = "";
            filtro.end = "";
            getElement("[name=project]", this).value = "";
            resetSelect2Ajax(getElement("[name=project]", this));
            filtro.project = "";
            getElement("[name=accountstart]", this).value = "";
            resetSelect2Ajax(getElement("[name=accountstart]", this));
            filtro.accountstart = "";
            getElement("[name=accountend]", this).value = "";
            resetSelect2Ajax(getElement("[name=accountend]", this));
            filtro.accountend = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("transactions-module", TransactionsModule, {
    extends: "section",
});
