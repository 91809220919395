import { getData } from "../../helpers";
import { CompanyModal as Company } from "../settings/settings/companies/modals/store";

class NavbarModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        this.addEventListener("click", (e) => {
            const BtnNew = e.target.closest("[name=new]");

            if (BtnNew) {
                Company.create(
                    getData(BtnNew, "data-bs-target"),
                    getData(BtnNew, "title"),
                    getData(BtnNew, "data-url")
                );
            }
        });
    }
}

window.customElements.define("navbar-module", NavbarModule, {
    extends: "header",
});
