class ProfileModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
    }
}

window.customElements.define("profile-module", ProfileModule, {
    extends: "section",
});
