import { dataCategory } from "../../../../../components/selectCategory";
import {
    b64Uri,
    formatCurrency,
    getData,
    getElement,
    getJson,
    resetForm,
    resetSelect2Ajax,
    setSelect2Ajax,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import { dataProductCodes } from "./codes";

const dataProductCategories = { categories: [], dt: null };

export class ProductModal extends HTMLDivElement {
    connectedCallback() {
        this.addEvents();
        this.dtCategories();
    }

    addEvents() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.category.addEventListener("aj:select2", (e) => {
            let category = e.detail.data;
            category.buttons = null;
            category.main = dataProductCategories.categories.length ? 0 : 1;
            dataCategory.categories.push(category.id);
            dataProductCategories.categories.push(category);
            dataProductCategories.dt.reloadData(
                dataProductCategories.categories
            );
            resetSelect2Ajax(Form.elements.category);
        });

        Form.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.name == "save") {
                if (dataProductCategories.categories.length) {
                    Form.elements.categories.value = b64Uri(
                        dataProductCategories.categories
                    );
                }

                if (Form.reportValidity()) {
                    Form.submit();
                }
            }

            if (getData(Target, "data-target") == "delete") {
                // Eliminar el target
                dataProductCategories.categories =
                    dataProductCategories.categories.filter(
                        (item) => item.id != getData(Target, "data-id")
                    );

                // si el eliminado era el principal asignar principal al primero
                if (
                    dataProductCategories.categories.some((item) => !item.main)
                ) {
                    dataProductCategories.categories[0].main = 1;
                }

                // quitar del filtro del select
                dataCategory.categories = dataCategory.categories.filter(
                    (item) => item != getData(Target, "data-id")
                );

                // recargar la data
                dataProductCategories.dt.reloadData(
                    dataProductCategories.categories
                );
            }

            if (Target.name == "main") {
                dataProductCategories.categories.forEach((item) => {
                    item.main = 0;
                    if (item.id == Target.value) {
                        item.main = 1;
                    }
                });
                dataProductCategories.dt.reloadData(
                    dataProductCategories.categories
                );
            }
        });
    }

    dtCategories() {
        let configDt = {
            ordering: false,
            info: false,
            lengthChange: false,
            stateSave: false,
            pageLength: 3,
            searching: false,
        };

        configDt.columns = [
            {
                data: "name",
            },
            {
                data: "main",
                width: "7%",
                class: "text-center",
                render: (data, type, row) => {
                    let checked = "";
                    if (data) {
                        checked = "checked";
                    }
                    let checkBox = `<div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="main" value="${row.id}" ${checked}>
                                    </div>`;
                    return checkBox;
                },
            },
            {
                data: "buttons",
                width: "5%",
                class: "text-center",
                render: (data, type, row) => {
                    let buttons = `<i class="fas fa-trash-alt text-danger" role="button" data-target="delete" data-id="${row.id}"></i>`;
                    return buttons;
                },
            },
        ];

        dataProductCategories.dt = new Dt(configDt);
        dataProductCategories.dt.dataTable(
            getElement("[data-table=dtCategories]", this)
        );
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        dataProductCodes.codes = [];
        dataProductCodes.dt.reloadData(dataProductCodes.codes);
        dataProductCategories.categories = [];
        dataProductCategories.dt.reloadData(dataProductCategories.categories);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.name;
        Form.elements.description.value = data.description;
        setSelect2Ajax(
            Form.elements.tax_id,
            data.tax.id,
            data.tax.description,
            data.tax
        );
        if (data.metric)
            setSelect2Ajax(
                Form.elements.metric_id,
                data.metric.id,
                data.metric.name,
                data.metric
            );
        Form.elements.price.value = formatCurrency(
            data.managements[0].pivot.price,
            "decimal"
        );
        Form.elements.stock.value = formatCurrency(
            data.managements[0].pivot.stock,
            "decimal"
        );

        dataProductCodes.codes = data.codes;
        dataProductCodes.dt.reloadData(dataProductCodes.codes);
        dataProductCategories.categories = data.categories;
        dataProductCategories.dt.reloadData(dataProductCategories.categories);

        // la categoría siempre se genera antes de enviar esta línea no es necesaria
        Form.elements.categories.value = b64Uri(
            dataProductCategories.categories
        );
        Form.elements.codes.value = b64Uri(dataProductCodes.codes);
    }
}

window.customElements.define("product-modal", ProductModal, {
    extends: "div",
});
