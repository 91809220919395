import {
    alertHTML,
    b64Uri,
    bodyRequest,
    getData,
    getJson,
    requestOptions,
    setSelect2Ajax,
} from "../../../../../../../helpers";

export class AccountingplansCustomer extends HTMLDivElement {
    connectedCallback() {
        this.load();
        this.addEvents();
    }

    test() {
        // No eliminar este método
        // console.log(this);
    }

    load() {
        const accountings = document.querySelectorAll(
            "[data-setAccounting=accounting]"
        );

        accountings.forEach((element) => {
            let data = getJson(b64Uri(getData(element, "data-row"), "decode"));

            if (data) {
                setSelect2Ajax(
                    element,
                    data.id,
                    `${data.number} - ${data.name}`
                );
            }
        });
    }

    addEvents() {
        this.addEventListener("aj:select2", (e) => {
            if (e.target.getAttribute("data-setAccounting")) {
                bodyRequest.set("attribute", e.target.name);
                bodyRequest.set("accounting", e.detail.data.id);
                fetch(
                    getData(this, "data-urlSetAccountingplan"),
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((data) => {
                        alertHTML(data.success, "success");
                    })
                    .catch((error) => console.log(error));
            }
        });
    }
}

window.customElements.define(
    "accountingplans-customer",
    AccountingplansCustomer,
    {
        extends: "div",
    }
);
