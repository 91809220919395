import {
    confirmDelete,
    formatCurrency,
    getData,
    getElement,
    setSelect2Ajax,
} from "../helpers";
import { ReceiptModal } from "../views/admin/sales/receipts/modals/store";

class EditreceiptButton extends HTMLButtonElement {
    connectedCallback() {
        this.load(this);
    }

    load = (element) => {
        const modal = ReceiptModal.create(
            getData(element, "data-bs-target"),
            getData(element, "data-url"),
            getData(element, "data-receipt"),
            getData(element, "data-data"),
            getData(element, "data-management")
        );
        element.addEventListener("click", () => {
            let data = JSON.parse(atob(getData(element, "data-data")));
            getElement("[name=receiptdate]", getElement(modal)).value =
                data.date_receipt;
            getElement("[name=total_receipt]", getElement(modal)).value =
                formatCurrency(data.amount);
            getElement("[name=type]", getElement(modal)).value = data.type;
            if (data.receipt) {
                getElement("[name=receipt]", getElement(modal)).checked = true;
            } else {
                getElement("[name=receipt]", getElement(modal)).checked = false;
            }

            if (data.bank) {
                setSelect2Ajax(
                    getElement("[name=bank]", getElement(modal)),
                    data.bank.id,
                    data.bank.account
                );
            }
            const table = getElement("[name=table-body]", getElement(modal));
            let html = "";
            data.saleinvoices.forEach(function (inv) {
                console.log(inv);
                html += "<tr>";
                html += "<td>" + inv.ref + "</td>";
                html += "<td>" + inv.date_receipt + "</td>";
                let amountinv = 0;
                inv.saleinvoicelines.forEach(function (elem) {
                    amountinv +=
                        parseFloat(elem.quantity) *
                        (parseFloat(elem.unit_price) +
                            parseFloat(elem.unit_tax));
                });
                html +=
                    "<td>" +
                    formatCurrency(
                        amountinv - (inv.receipt - inv.pivot.amount)
                    ) +
                    "</td>";
                html +=
                    "<td><div class='input-group input-group-sm mt-1'><input type='text' class='form-control form-control-sm' data-ref='" +
                    data.ref +
                    "' name='receipt_" +
                    inv.id +
                    "' value='" +
                    inv.pivot.amount +
                    "'/><i class='input-group-text fa-solid fa-money-bill-transfer pt-2' name='all'></i></div></td>";
                html += "</tr>";
            });
            table.innerHTML = html;
        });
    };
}

window.customElements.define("editreceipt-button", EditreceiptButton, {
    extends: "button",
});
