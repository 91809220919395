import { getData } from "../helpers";
import { ShareCustomer } from "../views/admin/sales/customers/modals/share";

class ShareCustomers extends HTMLButtonElement {
    connectedCallback() {
        this.test();
        this.load();
        this.events();
    }

    test() {
        // No borrar este método, pero sí su contenido
    }

    load() {
        //
    }

    events() {
        this.addEventListener("click", () => {
            let view = this.name == "shareView";
            ShareCustomer.shareCompanies(
                getData(this, "data-bs-target"),
                getData(this, "title"),
                getData(this, "data-url"),
                getData(this, "data-data"),
                view
            );
        });
    }
}

window.customElements.define("share-customers", ShareCustomers, {
    extends: "button",
});
