import {
    b64Uri,
    defaultSelect2,
    getElement,
    getJson,
    setSelect2Ajax,
} from "../../../../../helpers";
import { CompanyModal as Company } from "../../../../settings/settings/companies/modals/store";

export class SupplierModal {
    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));

        Form.elements.supplier.setAttribute("required", "");

        Company.create(modal, title, url);

        defaultSelect2(
            Form.elements.paymentmethod_id,
            "contado",
            "paymentmethodUrlSelect"
        );
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));

        Company.edit(modal, title, url, b64Uri(data.company));

        const Form = getElement("[data-form=form]", getElement(modal));

        if (data.paymentmethod) {
            setSelect2Ajax(
                Form.elements.paymentmethod_id,
                data.paymentmethod.id,
                data.paymentmethod.name
            );
        }
        let acSupplier = data.accountingplans.find((item) =>
            item.name.match(/\bproveedor\b/i)
        ); // \b indica el principio y find e la palabra
        let acCreditor = data.accountingplans.find((item) =>
            item.name.match(/\bacreedor\b/i)
        ); // \b indica el principio y find e la palabra
        Form.elements.supplier.value = acSupplier?.accountingplan_id ?? "";
        Form.elements.creditor.value = acCreditor?.accountingplan_id ?? "";
        Form.elements.description.value = data.description;
    }
}
