import { getData, getElement } from "../../../../helpers";
import { WithholdingModal } from "./modals/models";

class WithholdingSumaryModule extends HTMLElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear
        // Eventos
        BtnNew?.addEventListener("click", (e) => {
            WithholdingModal.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "title"),
                getData(BtnNew, "data-url")
            );
        });
    }
}

window.customElements.define(
    "withholdingsumary-module",
    WithholdingSumaryModule,
    {
        extends: "section",
    }
);
